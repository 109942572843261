var GetWindowHeight = (function () {
    function GetWindowHeight() {
        this._rootElement = document.documentElement;
        this._$w = window;
        this._lastResizeHeight = 0;
        this._lastResizeWidth = 0;
        this._init();
    }
    GetWindowHeight.prototype._init = function () {
        var _this = this;
        this._setWindowSizes();
        this._throttleEvent('resize', function () { return _this._setWindowSizes(); }, { passive: true });
    };
    GetWindowHeight.prototype._setCssVariables = function (el, variableObj) {
        for (var _i = 0, _a = Object.entries(variableObj); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            el.style.setProperty(key, String(value));
        }
    };
    GetWindowHeight.prototype._setWindowSizes = function () {
        var viewportHeight = this._$w.innerHeight;
        var viewportWidth = this._$w.innerWidth;
        if ((viewportHeight !== this._lastResizeHeight) || (viewportWidth !== this._lastResizeWidth)) {
            this._setCssVariables(this._rootElement, {
                '--viewportHeight': viewportHeight + "px",
                '--viewportWidth': viewportWidth + "px",
            });
            this._lastResizeHeight = viewportHeight;
            this._lastResizeWidth = viewportWidth;
        }
    };
    GetWindowHeight.prototype._throttleEvent = function (type, callback, listenerOptions) {
        var _this = this;
        var running = false;
        var func = function () {
            if (running) {
                return;
            }
            running = true;
            _this._$w.requestAnimationFrame(function () {
                callback && callback();
                running = false;
            });
        };
        this._$w.addEventListener(type, func, listenerOptions);
    };
    return GetWindowHeight;
}());
export { GetWindowHeight };
