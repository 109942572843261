import { map } from "toolbox/utils/iterable-iterator/map";
import { renderLoop } from "toolbox/utils/render-loop";
import { specToLineMap } from "./spec-to-line-map";
import { CssClass } from "./css-class";
import { getVisibleDistanceFromRoot } from "toolbox/utils/dom/position/get-visible-distance-from-root";
import { Vector2d } from "toolbox/utils/math/geometry/vector-2d";
import { setStyle } from "toolbox/utils/dom/style/set-style";
import { DynamicDefaultMap } from "toolbox/utils/map/dynamic-default";
var WINGSPAN_DASHED_LINE_HEIGHT = .69;
var LineHoverEffect = (function () {
    function LineHoverEffect(container, spec, line, modifier) {
        var HOVER_CLASS = CssClass.BASE + "--" + modifier;
        this.line_ = line;
        this.spec_ = spec;
        this.desktopOuterCircle_ =
            container.querySelector("." + CssClass.DESKTOP_OUTER_CIRCLE);
        this.mobileOuterCircle_ =
            container.querySelector("." + CssClass.MOBILE_SPECS_AND_STATS_AIRCRAFT + " " +
                ("." + CssClass.MOBILE_OUTER_CIRCLE));
        this.lineToSpecFromCircle_ =
            this.line_.querySelector("." + CssClass.LINE_TO_SPEC_FROM_CIRCLE);
        this.angledLineFromCircle_ =
            this.line_.querySelector("." + CssClass.ANGLED_LINE_FROM_CIRCLE);
        this.lineToSpecFromCenter_ =
            this.line_.querySelector("." + CssClass.LINE_TO_SPEC_FROM_CENTER);
        this.verticalLineToWingspan_ =
            this.line_.querySelector("." + CssClass.VERTICAL_LINE_TO_WINGSPAN);
        this.dashedLineForWingspan_ =
            this.line_.querySelector("." + CssClass.DASHED_LINE);
        this.cachedPositions_ =
            DynamicDefaultMap.usingFunction(function (element) { return getVisibleDistanceFromRoot(element); });
        this.circleRadius_ = null;
        this.spec_.addEventListener('mouseover', function () { return container.classList.add(HOVER_CLASS); });
        this.spec_.addEventListener('mouseout', function () { return container.classList.remove(HOVER_CLASS); });
        this.renderLoop_();
    }
    LineHoverEffect.prototype.clearCaches_ = function () {
        this.circleRadius_ = null;
        this.cachedPositions_.clear();
    };
    LineHoverEffect.prototype.getCircle_ = function () {
        return window.innerWidth <= 1024 ?
            this.mobileOuterCircle_ : this.desktopOuterCircle_;
    };
    LineHoverEffect.prototype.getCircleRadius_ = function () {
        if (this.circleRadius_ === null) {
            this.circleRadius_ = this.getCircle_().offsetHeight / 2;
        }
        return this.circleRadius_;
    };
    LineHoverEffect.prototype.isLeft_ = function () {
        return this.getSpecPosition_().getX() < this.getCircleCenter_().getX();
    };
    LineHoverEffect.prototype.getFlatLineStartPosition_ = function () {
        if (this.isLeft_()) {
            return this.getSpecBottomLeft_();
        }
        else {
            return this.getSpecBottomRight_()
                .subtract(new Vector2d(this.getFlatLineWidth(), 0));
        }
    };
    LineHoverEffect.prototype.getFlatLineEndNearCircle_ = function () {
        if (this.isLeft_()) {
            return this.getFlatLineStartPosition_()
                .add(new Vector2d(this.getFlatLineWidth(), 0));
        }
        else {
            return this.getFlatLineStartPosition_();
        }
    };
    LineHoverEffect.prototype.adjustLineToSpecFromCenter_ = function () {
        var _this = this;
        var isLeft = this.isLeft_();
        var edge = isLeft ? this.getSpecBottomLeft_() : this.getSpecBottomRight_();
        var width = this.getCircleCenter_().getX() - edge.getX();
        var start = isLeft ? edge : edge.subtract(new Vector2d(width, 0));
        var delta = start.subtract(this.getLinePosition_());
        renderLoop.anyMutate(function () {
            delta.positionElementByTranslation(_this.lineToSpecFromCenter_);
            setStyle(_this.lineToSpecFromCenter_, 'width', width + "px");
        });
    };
    LineHoverEffect.prototype.adjustLineToSpecFromCircle_ = function () {
        var _this = this;
        var delta = this.getFlatLineStartPosition_().subtract(this.getLinePosition_());
        var width = this.getFlatLineWidth();
        var angleLineLength = this.getDesiredAngleLineLength_();
        var angleLineAngle = this.getAngleLineAngle_();
        renderLoop.anyMutate(function () {
            delta.positionElementByTranslation(_this.lineToSpecFromCircle_);
            setStyle(_this.lineToSpecFromCircle_, 'width', width + "px");
            setStyle(_this.angledLineFromCircle_, 'width', angleLineLength + "px");
            setStyle(_this.angledLineFromCircle_, 'transform', "rotate(" + angleLineAngle + "deg)");
        });
    };
    LineHoverEffect.prototype.getSpecPosition_ = function () {
        return this.cachedPositions_.get(this.spec_);
    };
    LineHoverEffect.prototype.getLinePosition_ = function () {
        return this.cachedPositions_.get(this.line_);
    };
    LineHoverEffect.prototype.getSpecBottomLeft_ = function () {
        return this.getSpecPosition_()
            .add(new Vector2d(0, this.spec_.offsetHeight));
    };
    LineHoverEffect.prototype.getSpecBottomRight_ = function () {
        return this.getSpecPosition_()
            .add(new Vector2d(this.spec_.offsetWidth, this.spec_.offsetHeight));
    };
    LineHoverEffect.prototype.getDashedLineForWingspanVerticalCenter_ = function () {
        var dashedLineHeight = WINGSPAN_DASHED_LINE_HEIGHT * this.line_.offsetHeight;
        var yCenter = this.cachedPositions_.get(this.dashedLineForWingspan_).getY() +
            dashedLineHeight / 2;
        return new Vector2d(this.getCircleCenter_().getX(), yCenter);
    };
    LineHoverEffect.prototype.adjustVerticalLineToWingspan_ = function () {
        var _this = this;
        var endY = this.getSpecBottomLeft_().getY();
        var startY = this.getDashedLineForWingspanVerticalCenter_();
        var delta = startY.subtract(this.getLinePosition_());
        var height = Math.abs(startY.getY() - endY);
        renderLoop.anyMutate(function () {
            delta.positionElementByTranslation(_this.verticalLineToWingspan_);
            setStyle(_this.verticalLineToWingspan_, 'height', height + "px");
        });
    };
    LineHoverEffect.prototype.renderLoop_ = function () {
        var _this = this;
        renderLoop.anyMeasure(function () {
            _this.clearCaches_();
            renderLoop.anyCleanup(function () { return _this.renderLoop_(); });
            if (_this.verticalLineToWingspan_) {
                _this.adjustVerticalLineToWingspan_();
            }
            if (_this.lineToSpecFromCircle_) {
                _this.adjustLineToSpecFromCircle_();
            }
            if (_this.lineToSpecFromCenter_) {
                _this.adjustLineToSpecFromCenter_();
            }
        });
    };
    LineHoverEffect.prototype.getCircleCenter_ = function () {
        return this.cachedPositions_.get(this.getCircle_())
            .add(new Vector2d(this.getCircleRadius_(), this.getCircleRadius_()));
    };
    LineHoverEffect.prototype.getAngleLineWidth_ = function () {
        return this.getCircleRadius_();
    };
    LineHoverEffect.prototype.getFlatLineWidth = function () {
        return this.getWidthFromCircleCenter_() - this.getAngleLineWidth_();
    };
    LineHoverEffect.prototype.getAngleLineHeight_ = function () {
        return this.getCircleCenter_().getY() -
            this.getFlatLineEndNearCircle_().getY();
    };
    LineHoverEffect.prototype.getDesiredAngleLineLength_ = function () {
        return Math.sqrt(Math.pow(this.getAngleLineWidth_(), 2) +
            Math.pow(this.getAngleLineHeight_(), 2)) -
            this.getCircleRadius_();
    };
    LineHoverEffect.prototype.getWidthFromCircleCenter_ = function () {
        var edge = this.isLeft_() ?
            this.getSpecPosition_().getX() :
            this.getSpecPosition_().getX() + this.spec_.offsetWidth;
        return Math.abs(edge - this.getCircleCenter_().getX());
    };
    LineHoverEffect.prototype.getAngleLineAngle_ = function () {
        var rawAngle = Math.atan(this.getAngleLineHeight_() / this.getAngleLineWidth_()) *
            180 / Math.PI;
        return this.isLeft_() ? rawAngle : -rawAngle;
    };
    return LineHoverEffect;
}());
function createLineHoverEffects(element) {
    map(specToLineMap.entries(), function (_a) {
        var specCssClass = _a[0], lineCssClass = _a[1];
        var spec = element.querySelector("." + specCssClass);
        var lines = Array.from(element.querySelectorAll("." + lineCssClass));
        var modifier = lineCssClass.split('--')[1];
        return lines.map(function (line) { return new LineHoverEffect(element, spec, line, modifier); });
    });
}
export { createLineHoverEffects };
