import { Scroll } from 'toolbox/utils/cached-vectors/scroll';
import { renderLoop } from "toolbox/utils/render-loop";
import { setStyle } from "toolbox/utils/dom/style/set-style";
var HomeHeroController = (function () {
    function HomeHeroController($element) {
        this.element_ = $element[0];
        this.renderLoop_();
    }
    HomeHeroController.prototype.renderLoop_ = function () {
        var _this = this;
        renderLoop.measure(function () {
            renderLoop.cleanup(function () { return _this.renderLoop_(); });
            if (Scroll.getSingleton().getPosition().y > window.innerHeight * 2) {
                renderLoop.mutate(function () { return setStyle(_this.element_, 'visibility', 'hidden'); });
            }
            else {
                renderLoop.mutate(function () { return setStyle(_this.element_, 'visibility', ''); });
            }
        });
    };
    HomeHeroController.$inject = ['$element'];
    return HomeHeroController;
}());
export { HomeHeroController };
