var UTMPreloaderController = (function () {
    function UTMPreloaderController($element) {
        this._$preloader = $element[0];
        this._$logo = this._$preloader.querySelectorAll('.utm-preloader__logo')[0];
        this._isActive = true;
        this._init();
    }
    UTMPreloaderController.prototype._init = function () {
        var _this = this;
        this._delay(1000, function () {
            _this._$logo.classList.remove('fade-in-up');
            _this._$logo.classList.add('fade-out-up');
        });
        this._delay(1500, function () {
            _this._$preloader.classList.add('fade-out');
            _this._$preloader.classList.remove('active');
        });
    };
    UTMPreloaderController.prototype._delay = function (t, fn) {
        return setTimeout(fn, t);
    };
    UTMPreloaderController.$inject = ['$element'];
    return UTMPreloaderController;
}());
export { UTMPreloaderController };
