import { ScrollEffect } from 'toolbox/components/scroll-effect/base';
import { DistanceFunction } from 'toolbox/components/scroll-effect/distance-function';
import { Scroll } from 'toolbox/utils/cached-vectors/scroll';
import { renderLoop } from 'toolbox/utils/render-loop';
import { setStyle } from "toolbox/utils/dom/style/set-style";
import { getOffsetFromAncestorIgnoringSticky } from "toolbox/utils/dom/position/vertical/get-offset-from-ancestor-ignoring-sticky";
import { toggleCssClass } from "toolbox/utils/dom/class/toggle-css-class";
import { NumericRange } from "toolbox/utils/math/numeric-range";
var scroll = Scroll.getSingleton();
var COUNTRY_NAV_HEIGHT = 62;
var CssClass;
(function (CssClass) {
    CssClass["TOP_NAV"] = "top-nav";
    CssClass["COUNTRY_NAV_PAST_THRESHOLD"] = "country-nav--past-threshold";
    CssClass["COUNTRY_NAV_SCROLLING_UP"] = "country-nav--scrolling-up";
    CssClass["COUNTRY_NAV_NO_TRANSITION"] = "country-nav--no-transition";
})(CssClass || (CssClass = {}));
var TOP_NAV = document.querySelector("." + CssClass.TOP_NAV);
var NavScrollEffect = (function () {
    function NavScrollEffect(ctrl) {
        this.ctrl_ = ctrl;
        this.scrollUpStartTime_ = Number.POSITIVE_INFINITY;
    }
    NavScrollEffect.prototype.run = function (target, distanceAsPx) {
        var castTarget = target;
        var isExpanded = this.ctrl_.isExpanded();
        var isScrollingUp = scroll.isScrollingUp();
        var countryNavHeight = isExpanded ? castTarget.offsetHeight : COUNTRY_NAV_HEIGHT;
        var finesseRangeStart = window.innerHeight - TOP_NAV.offsetHeight - countryNavHeight;
        var finesseRangeEnd = window.innerHeight - COUNTRY_NAV_HEIGHT;
        var finesseRange = new NumericRange(finesseRangeStart, finesseRangeEnd);
        var threshold = isScrollingUp ?
            window.innerHeight - TOP_NAV.offsetHeight :
            window.innerHeight;
        var pastThreshold = distanceAsPx >= threshold;
        renderLoop.mutate(function () {
            toggleCssClass(target, CssClass.COUNTRY_NAV_PAST_THRESHOLD, pastThreshold);
            toggleCssClass(target, CssClass.COUNTRY_NAV_SCROLLING_UP, isScrollingUp);
        });
        var inFinesseRange = finesseRange.contains(distanceAsPx);
        var finesseScrollUp = inFinesseRange && isScrollingUp;
        var now = +new Date();
        var hasTransitioned = now - this.scrollUpStartTime_ > 200;
        var removeTransition = (!pastThreshold && !inFinesseRange) ||
            (inFinesseRange && hasTransitioned);
        if (finesseScrollUp) {
            var targetBottomDistanceFromWindowTop = TOP_NAV.offsetHeight + countryNavHeight;
            var unstuckDistanceOffscreen = getOffsetFromAncestorIgnoringSticky(castTarget) - distanceAsPx;
            var translateY_1 = targetBottomDistanceFromWindowTop - unstuckDistanceOffscreen;
            renderLoop.mutate(function () {
                setStyle(castTarget, 'transform', "translateY(calc(-100% + " + translateY_1 + "px))");
            });
        }
        else {
            renderLoop.mutate(function () { return setStyle(castTarget, 'transform', ''); });
        }
        renderLoop.mutate(function () {
            toggleCssClass(target, CssClass.COUNTRY_NAV_NO_TRANSITION, removeTransition);
        });
        if (isScrollingUp) {
            if (this.scrollUpStartTime_ === Number.POSITIVE_INFINITY) {
                this.scrollUpStartTime_ = now;
            }
        }
        else {
            this.scrollUpStartTime_ = Number.POSITIVE_INFINITY;
        }
    };
    NavScrollEffect.prototype.destroy = function () {
    };
    return NavScrollEffect;
}());
var CountryNavController = (function () {
    function CountryNavController($element) {
        this.element_ = $element[0];
        this.expanded_ = false;
        this.lastHeight_ = null;
        new ScrollEffect(this.element_, {
            getDistanceFunction: DistanceFunction.DOCUMENT_SCROLL,
            effects: [new NavScrollEffect(this)],
        });
        this.updateMarginBottom_();
    }
    CountryNavController.prototype.updateMarginBottom_ = function () {
        var _this = this;
        renderLoop.measure(function () {
            renderLoop.cleanup(function () { return _this.updateMarginBottom_(); });
            if (window.innerWidth <= 768) {
                setStyle(_this.element_, 'margin-bottom', '');
                _this.lastHeight_ = null;
            }
            else {
                var height = _this.element_.offsetHeight;
                if (height !== _this.lastHeight_) {
                    var marginBottom = COUNTRY_NAV_HEIGHT - height;
                    setStyle(_this.element_, 'margin-bottom', marginBottom + "px");
                    _this.lastHeight_ = height;
                }
            }
        });
    };
    CountryNavController.prototype.toggleNav = function () {
        this.expanded_ = !this.expanded_;
    };
    CountryNavController.prototype.isExpanded = function () {
        return this.expanded_;
    };
    CountryNavController.$inject = ['$element'];
    return CountryNavController;
}());
export { CountryNavController };
