var ResourceArticlesController = (function () {
    function ResourceArticlesController($element) {
        this.classes = {
            highlight: 'highlight',
            featuredResource: 'resource-articles__featured__resource',
            featuredResourceLeft: 'resource-articles__featured__resource__left',
            featuredResourceHidden: 'resource-articles__featured__resource--hidden',
            categoryFilterActive: 'resource-articles__category-filter__category--active',
            tagFilterActive: 'resource-articles__tag-filter__tag--active',
            featuredResourceTags: 'resource-articles__featured__resource__tags',
            eyebrow: 'eyebrow',
            subtitle: 'subtitle'
        };
        this.attrs = {
            title: 'data-title',
            cardBody: 'data-card-body',
            categories: 'data-categories',
            tags: 'data-tags',
            resources: 'data-resources',
        };
        this.$el = $element[0];
        this.resourceData = JSON.parse(this.$el.getAttribute(this.attrs.resources));
        this.categoryData = JSON.parse(this.$el.getAttribute(this.attrs.categories));
        this.tagData = JSON.parse(this.$el.getAttribute(this.attrs.tags));
        this.allCategories = {};
        this.categoryMap = {};
        this.allTags = {};
        this.tagMap = {};
        this.showClearAllBtn = false;
        this.activeFilters = {
            search: '',
            category: '',
            tags: []
        };
        this.$resources = this.$el.querySelectorAll("." + this.classes.featuredResource);
        this.activeResourceCount = this.$resources.length;
        this.init_();
    }
    ResourceArticlesController.prototype.init_ = function () {
        var _this = this;
        this.getallCategories();
        this.getallTags();
        window.addEventListener('DOMContentLoaded', function () {
            _this.updateResults('showTopFourPinned');
        });
    };
    ResourceArticlesController.prototype.handleKeyUp = function (e) {
        if (e.code === 'Enter') {
            this.searchSubmit();
        }
    };
    ResourceArticlesController.prototype.searchSubmit = function () {
        this.activeFilters.search = this.searchTerm ? String(this.searchTerm).toLowerCase() : null;
        this.updateResults();
    };
    ResourceArticlesController.prototype.clearSearch = function () {
        var _this = this;
        if (this.searchTerm) {
            this.searchTerm = null;
            this.searchSubmit();
        }
        this.$el.querySelectorAll("." + this.classes.highlight).forEach(function (element) {
            element.classList.remove(_this.classes.highlight);
        });
    };
    ResourceArticlesController.prototype.clearCategorySelections = function () {
        var _this = this;
        var activeCategoryElements = this.$el.querySelectorAll("." + this.classes.categoryFilterActive);
        activeCategoryElements.forEach(function (el) {
            el.classList.remove(_this.classes.categoryFilterActive);
        });
        for (var category in this.allCategories) {
            this.allCategories[category].selected = false;
        }
        this.activeFilters.category = null;
    };
    ResourceArticlesController.prototype.clearTagSelections = function () {
        var _this = this;
        var activeTagElements = this.$el.querySelectorAll("." + this.classes.tagFilterActive);
        activeTagElements.forEach(function (el) {
            el.classList.remove(_this.classes.tagFilterActive);
        });
        for (var tag in this.allTags) {
            this.allTags[tag].selected = false;
        }
        this.activeFilters.tags = [];
    };
    ResourceArticlesController.prototype.clearAll = function () {
        var _this = this;
        var resourceElements = this.$el.querySelectorAll("." + this.classes.featuredResource);
        this.clearSearch();
        this.clearCategorySelections();
        this.clearTagSelections();
        this.activeResourceCount = this.$resources.length;
        resourceElements.forEach(function (el) {
            el.classList.remove("" + _this.classes.featuredResourceHidden);
        });
        this.updateResults('showTopFourPinned');
    };
    ResourceArticlesController.prototype.selectCategoryFilter = function (event, id) {
        if (this.allCategories[id].selected) {
            for (var category in this.allCategories) {
                this.allCategories[category].selected = false;
            }
            this.activeFilters.category = null;
        }
        else {
            for (var category in this.allCategories) {
                this.allCategories[category].selected = false;
            }
            this.allCategories[id].selected = true;
            this.activeFilters.category = id;
        }
        this.updateResults();
    };
    ResourceArticlesController.prototype.selectTagFilter = function (event, id) {
        var action = this.allTags[id].selected ? 'remove' : 'add';
        if (action === 'add') {
            this.allTags[id].selected = true;
            this.activeFilters.tags.push(id);
        }
        else {
            this.allTags[id].selected = false;
            this.activeFilters.tags.splice(this.activeFilters.tags.indexOf(id), 1);
        }
        this.updateResults();
    };
    ResourceArticlesController.prototype.getallCategories = function () {
        var _this = this;
        this.categoryData.forEach(function (category) {
            _this.categoryMap[category.document_id] = category.name;
        });
        this.resourceData.forEach(function (resource) {
            resource.categories.forEach(function (category) {
                _this.allCategories[category.document_id] = {
                    name: _this.categoryMap[category.document_id],
                    selected: false
                };
            });
        });
    };
    ResourceArticlesController.prototype.getallTags = function () {
        var _this = this;
        this.tagData.forEach(function (tag) {
            _this.tagMap[tag.document_id] = tag.name;
        });
        this.resourceData.forEach(function (resource) {
            resource.tags.forEach(function (tag) {
                _this.allTags[tag.document_id] = {
                    name: _this.tagMap[tag.document_id],
                    selected: false
                };
            });
        });
    };
    ResourceArticlesController.prototype.updateResults = function (option) {
        var _this = this;
        var searchActive = Boolean(this.activeFilters.search);
        var categoryActive = Boolean(this.activeFilters.category);
        var tagsActive = Boolean(this.activeFilters.tags.length);
        var showTopFourPinned = option === 'showTopFourPinned';
        this.activeResourceCount = 0;
        this.$resources.forEach(function (resourceElement) {
            var searchMatch = false;
            var categoryMatch = false;
            var tagMatch = false;
            var show = true;
            var pinned = resourceElement.getAttribute('data-pinned') === 'True';
            var resourceElementSearchCopy = [
                resourceElement.getAttribute(_this.attrs.title),
                resourceElement.getAttribute(_this.attrs.cardBody)
            ];
            resourceElement.classList.remove("" + _this.classes.featuredResourceHidden);
            resourceElementSearchCopy.forEach(function (searchCopy) {
                if (!searchMatch && searchCopy.indexOf(_this.activeFilters.search) !== -1) {
                    searchMatch = true;
                }
            });
            JSON.parse(resourceElement.getAttribute(_this.attrs.categories)).forEach(function (category) {
                if (!categoryMatch && Number(_this.activeFilters.category) === Number(category.document_id)) {
                    categoryMatch = true;
                }
            });
            _this.activeFilters.tags.forEach(function (tag) {
                if (!tagMatch && resourceElement.getAttribute(_this.attrs.tags).indexOf(tag) !== -1) {
                    tagMatch = true;
                }
            });
            if (searchMatch) {
                _this.highlightText(_this.activeFilters.search, resourceElement);
            }
            if (searchActive && !categoryActive && !tagsActive) {
                if (!searchMatch) {
                    show = false;
                }
            }
            else if (!searchActive && categoryActive && !tagsActive) {
                if (!categoryMatch) {
                    show = false;
                }
            }
            else if (!searchActive && !categoryActive && tagsActive) {
                if (!tagMatch) {
                    show = false;
                }
            }
            else if (searchActive && categoryActive && !tagsActive) {
                if (!searchMatch || !categoryMatch) {
                    show = false;
                }
            }
            else if (searchActive && !categoryActive && tagsActive) {
                if (!searchMatch || !tagMatch) {
                    show = false;
                }
            }
            else if (!searchActive && categoryActive && tagsActive) {
                if (!categoryMatch || !tagMatch) {
                    show = false;
                }
            }
            else if (searchActive && categoryActive && tagsActive) {
                if (!searchMatch || !categoryMatch || !tagMatch) {
                    show = false;
                }
            }
            else if (showTopFourPinned) {
                if (pinned) {
                    show = true;
                }
                else {
                    show = false;
                }
            }
            if (show) {
                _this.activeResourceCount++;
            }
            else {
                _this.hideElement(resourceElement);
            }
        });
        if (!showTopFourPinned && !this.activeFilters.search && !this.activeFilters.category && this.activeFilters.tags.length === 0) {
            this.clearAll();
        }
    };
    ResourceArticlesController.prototype.hideElement = function (el) {
        el.classList.add(this.classes.featuredResourceHidden);
    };
    ResourceArticlesController.prototype.highlightText = function (text, el) {
        var _this = this;
        var currentHighlightedItems = this.$el.querySelectorAll("." + this.classes.featuredResource + " span." + this.classes.highlight);
        var textCases = [
            text,
            text.toLowerCase(),
            text.charAt(0).toUpperCase() + text.slice(1),
            text.toUpperCase()
        ];
        currentHighlightedItems.forEach(function (item) {
            if (item.outerHTML.toLowerCase().indexOf(text.toLowerCase()) === -1 || !text || text.length < 2) {
                item.classList.remove(_this.classes.highlight);
            }
        });
        if (text.length > 1) {
            textCases.forEach(function (string) {
                el.querySelectorAll("." + _this.classes.featuredResourceLeft + "." + _this.classes.eyebrow + ", ." + _this.classes.subtitle + ", p, ." + _this.classes.featuredResourceTags).forEach(function (subEl) {
                    if (subEl.innerHTML.indexOf(string) !== -1) {
                        subEl.innerHTML = subEl.innerHTML.substring(0, subEl.innerHTML.indexOf(string)) + "<span class='" + _this.classes.highlight + "'>" + subEl.innerHTML.substring(subEl.innerHTML.indexOf(string), subEl.innerHTML.indexOf(string) + string.length) + "</span>" + subEl.innerHTML.substring(subEl.innerHTML.indexOf(string) + string.length);
                    }
                });
            });
        }
    };
    ResourceArticlesController.$inject = ['$element'];
    return ResourceArticlesController;
}());
export { ResourceArticlesController };
