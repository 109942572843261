var TabSectionController = (function () {
    function TabSectionController($element) {
        this.element_ = $element[0];
        this.activeTab_ = '';
        this.parentContainer_ = '';
        this.init();
    }
    TabSectionController.prototype.init = function () {
        var initialTab = this.element_.getElementsByClassName('tab-section__tab')[0];
        var initialTabId = initialTab.getAttribute('data-id');
        var initialTabParent = {
            target: initialTab.getAttribute('data-target'),
            class: initialTab.getAttribute('data-parent')
        };
        if (initialTabId && initialTabParent.target && initialTabParent.class) {
            this.selectTab(initialTabId, initialTabParent);
        }
    };
    TabSectionController.prototype.selectTab = function (tabId, parentContainer) {
        this.activeTab_ = tabId;
        this.parentContainer_ = parentContainer;
        this.updateTabs();
        if (parentContainer) {
            this.updateParentContainer();
        }
    };
    TabSectionController.prototype.updateTabs = function () {
        var tabs = this.element_.getElementsByClassName('tab-section__tab');
        var contentItems = this.element_.getElementsByClassName('tab-section__content__item');
        var activeTabElement = this.element_.getElementsByClassName('tab-section__tab__' + this.activeTab_)[0];
        var activeContentItemElement = this.element_.getElementsByClassName('tab-section__content__' + this.activeTab_)[0];
        for (var _i = 0, tabs_1 = tabs; _i < tabs_1.length; _i++) {
            var tab = tabs_1[_i];
            tab.classList.remove('tab-section__tab--active');
        }
        for (var _a = 0, contentItems_1 = contentItems; _a < contentItems_1.length; _a++) {
            var contentItem = contentItems_1[_a];
            contentItem.classList.remove('tab-section__content__item--active');
        }
        activeTabElement.classList.add('tab-section__tab--active');
        activeContentItemElement.classList.add('tab-section__content__item--active');
    };
    TabSectionController.prototype.updateParentContainer = function () {
        var parentElement = document.getElementsByClassName(this.parentContainer_.class)[0];
        parentElement.setAttribute('data-' + this.parentContainer_.target, this.activeTab_);
    };
    TabSectionController.$inject = ['$element'];
    return TabSectionController;
}());
export { TabSectionController };
