import { Carousel } from "toolbox/components/carousel/carousel";
import { CarouselTimer } from "toolbox/components/carousel/timer";
import { CarouselSyncManager } from "toolbox/components/carousel/sync-manager";
var carouselSelector = '[utm-carousel]';
var carouselSlideSelector = '[utm-carousel-slide]';
var carouselSlideTriggerSelector = '[utm-carousel-slide-trigger]';
var carouselSlideProgressSelector = '[utm-carousel-slide-progress]';
var mobilecarouselTimerSelector = '.utm-grid-carousel__mobile-timer';
var UTMCarouselController = (function () {
    function UTMCarouselController($element) {
        this._$el = $element[0];
        this._intersectOpts = { threshold: 0.25 };
        this._speed = 5000;
        this._index = 0;
        this._$container = this._$el.querySelector(carouselSelector);
        this._$slides = Array.from(this._$el.querySelectorAll(carouselSlideSelector));
        this._$triggers = Array.from(this._$el.querySelectorAll(carouselSlideTriggerSelector));
        this._$progressBars = Array.from(this._$el.querySelectorAll(carouselSlideProgressSelector));
        this._$mobileTimers = Array.from(this._$el.querySelectorAll(mobilecarouselTimerSelector));
    }
    UTMCarouselController.prototype.init = function (config) {
        var _this = this;
        this._speed = config.speed || this._speed;
        this._autoplay = config.autoplay !== false;
        this._index = config.start_slide || this._index;
        this._pauseOnHover = config.pause_on_hover !== false;
        this._carousel = new Carousel(this._$container, this._$slides);
        this._triggerCarousel = new Carousel(this._$container, this._$triggers);
        this._mobileTimerCarousel = new Carousel(this._$container, this._$mobileTimers);
        if (this._autoplay) {
            this._carouselTimer = new CarouselTimer(this._carousel, this._speed);
        }
        this.syncWithCarousel(this._triggerCarousel);
        this.syncWithCarousel(this._mobileTimerCarousel);
        this._$progressBars.forEach(function (progressBar) {
            progressBar.style.animationDuration = _this._speed + "ms";
        });
        if (this._pauseOnHover) {
            this._$triggers.forEach(function (trigger, index) {
                trigger.addEventListener('mouseenter', function () { return _this.pause(trigger, _this._$slides[index]); });
                trigger.addEventListener('mouseleave', function () { return _this.play(trigger, _this._$slides[index]); });
            });
        }
        this.pause(this._$triggers[this._index], this._$slides[this._index]);
        this._inview();
    };
    UTMCarouselController.prototype.pause = function (trigger, slide) {
        var progressBar = trigger.querySelector(carouselSlideProgressSelector);
        var video = slide.querySelector('video');
        progressBar.classList.add('utm-grid-carousel__timer__progress--pause');
        if (this._autoplay) {
            this._carouselTimer.pause();
        }
        if (video) {
            console.log('video found', video);
        }
    };
    UTMCarouselController.prototype.play = function (trigger, slide) {
        var progressBar = trigger.querySelector(carouselSlideProgressSelector);
        var video = slide.querySelector('video');
        progressBar.classList.remove('utm-grid-carousel__timer__progress--pause');
        if (this._autoplay) {
            this._carouselTimer.play();
        }
        if (video) {
            console.log('video found', video);
        }
    };
    UTMCarouselController.prototype.syncWithCarousel = function (carousel) {
        CarouselSyncManager.getSingleton().syncCarousels(carousel, this._carousel);
    };
    UTMCarouselController.prototype.goToSlide = function (slideIndex) {
        this._carousel.transitionToIndex(slideIndex);
        this._carouselTimer.stop();
        this._carouselTimer.play();
    };
    UTMCarouselController.prototype._inview = function () {
        var _this = this;
        var observer = new IntersectionObserver(function (entries) {
            var entry = entries[0];
            var isInview = entry.isIntersecting;
            if (isInview) {
                _this.play(_this._$triggers[_this._index], _this._$slides[_this._index]);
            }
            else {
                _this.pause(_this._$triggers[_this._index], _this._$slides[_this._index]);
            }
        }, this._intersectOpts);
        observer.observe(this._$el);
    };
    UTMCarouselController.$inject = ['$element'];
    return UTMCarouselController;
}());
export { UTMCarouselController };
