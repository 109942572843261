import { Breakpoint } from "./breakpoint";
var BreakpointCheck = (function () {
    function BreakpointCheck() {
    }
    BreakpointCheck.GT_XS = function () { return window.innerWidth > Breakpoint.XS; };
    BreakpointCheck.LTE_XS = function () { return window.innerWidth <= Breakpoint.XS; };
    BreakpointCheck.GT_SM = function () { return window.innerWidth > Breakpoint.SM; };
    BreakpointCheck.LTE_SM = function () { return window.innerWidth <= Breakpoint.SM; };
    BreakpointCheck.GT_MD = function () { return window.innerWidth > Breakpoint.MD; };
    BreakpointCheck.LTE_MD = function () { return window.innerWidth <= Breakpoint.MD; };
    BreakpointCheck.GT_LG = function () { return window.innerWidth > Breakpoint.LG; };
    BreakpointCheck.LTE_LG = function () { return window.innerWidth <= Breakpoint.LG; };
    BreakpointCheck.GT_XL = function () { return window.innerWidth > Breakpoint.XL; };
    BreakpointCheck.LTE_XL = function () { return window.innerWidth <= Breakpoint.XL; };
    BreakpointCheck.GT_XXL = function () { return window.innerWidth > Breakpoint.XXL; };
    BreakpointCheck.LTE_XXL = function () { return window.innerWidth <= Breakpoint.XXL; };
    return BreakpointCheck;
}());
export { BreakpointCheck };
