var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { ScrollCarouselController } from "../scroll-carousel/scroll-carousel-controller";
import { renderLoop } from "toolbox/utils/render-loop";
import { generateFilledArray } from "toolbox/utils/array/generate-filled-array";
import { toggleCssClass } from "toolbox/utils/dom/class/toggle-css-class";
import { DynamicDefaultMap } from "toolbox/utils/map/dynamic-default";
import { CssClass } from "./css-class";
import { createSpecsCarousel } from "./create-specs-carousel";
import { createTabCarousels } from "./create-tab-headers-carousel";
import { HighlightCarousel } from "./highlight-carousel";
import { Tween } from "toolbox/components/scroll-effect/effects/tween/tween";
import { ScrollEffect } from "toolbox/components/scroll-effect/base";
import { DistanceFunction } from "toolbox/components/scroll-effect/distance-function";
import { getOffsetFromAncestorIgnoringSticky } from "toolbox/utils/dom/position/vertical/get-offset-from-ancestor-ignoring-sticky";
import { createLineHoverEffects } from "./create-line-hover-effects";
import { UserAgent } from "toolbox/utils/user-agent/user-agent";
import { Edge } from "toolbox/utils/user-agent/browser/edge";
var TRANSITION_START = .2;
var TRANSITION_END = .4;
var generatedCssClasses = DynamicDefaultMap.usingFunction(function (i) { return CssClass.BASE + "--" + i; });
function createTextTweens(element) {
    var textElements = Array.from(element.querySelectorAll("." + CssClass.BACKGROUND_TEXT));
    return textElements.map(function (text) { return new Tween([
        [TRANSITION_START, 'transform: translate(0vh, 0vh);'],
        [TRANSITION_END, 'transform: translate(-65vh, -100vh);'],
    ], { styleTarget: text }); });
}
function createGtXlTextContainerTweens(element) {
    var textContainerElements = Array.from(element.querySelectorAll("." + CssClass.BACKGROUND_TEXT_CONTAINER));
    return textContainerElements.map(function (container) { return new Tween([
        [TRANSITION_START, 'transform: translateX(0%) rotate(0deg);'],
        [TRANSITION_END, 'transform: translateX(100px) rotate(-90deg);'],
    ], { styleTarget: container }); });
}
function createLteXlTextContainerTweens(element) {
    var textContainerElements = Array.from(element.querySelectorAll("." + CssClass.BACKGROUND_TEXT_CONTAINER));
    return textContainerElements.map(function (container) { return new Tween([
        [TRANSITION_START, 'transform: translateX(0%) rotate(0deg);'],
        [
            TRANSITION_END,
            'transform: translateX(calc(6.94% - 13.88vh)) rotate(-90deg);'
        ],
    ], { styleTarget: container }); });
}
function createGtMdAircraftTweens(element) {
    var aircraftImage = element.querySelector("." + CssClass.IMAGE_AIRCRAFT);
    return [
        new Tween([
            [TRANSITION_START, 'transform: translateX(48px) rotate(40deg)'],
            [TRANSITION_END, 'transform: rotate(0deg)']
        ], { styleTarget: aircraftImage })
    ];
}
var AircraftController = (function () {
    function AircraftController($element, slideContainerCssClass, slideCssClass) {
        var _this = this;
        if (slideContainerCssClass === void 0) { slideContainerCssClass = CssClass.SLIDES_CONTAINER; }
        if (slideCssClass === void 0) { slideCssClass = CssClass.SLIDE; }
        this.element_ = $element[0];
        if (UserAgent.getBrowser() !== Edge) {
            createLineHoverEffects(this.element_);
        }
        this.scrollCarouselController_ =
            new ScrollCarouselController($element, slideContainerCssClass, slideCssClass);
        var defaultConfig = {
            getDistanceFunction: DistanceFunction.DOCUMENT_SCROLL,
            startDistance: function () { return getOffsetFromAncestorIgnoringSticky(_this.element_); },
            endDistance: function () {
                return getOffsetFromAncestorIgnoringSticky(_this.element_) +
                    _this.element_.offsetHeight - window.innerHeight;
            }
        };
        var aircraftTweens = createGtMdAircraftTweens(this.element_);
        var textTweens = createTextTweens(this.element_);
        var textAndImageTweens = UserAgent.getBrowser() === Edge ?
            aircraftTweens : __spreadArrays(textTweens, aircraftTweens);
        this.textAndImageScrollEffect_ =
            new ScrollEffect(this.element_, Object.assign({ condition: function () { return window.innerWidth > 1024; } }, defaultConfig, { effects: textAndImageTweens }));
        if (UserAgent.getBrowser() !== Edge) {
            this.textContainerScrollEffectGtXl_ =
                new ScrollEffect(this.element_, Object.assign({ condition: function () { return window.innerWidth > 1440; } }, defaultConfig, { effects: createGtXlTextContainerTweens(this.element_) }));
            this.textContainerScrollEffectLteXl_ =
                new ScrollEffect(this.element_, Object.assign({ condition: function () { return window.innerWidth <= 1440; } }, defaultConfig, { effects: createLteXlTextContainerTweens(this.element_) }));
        }
        this.init_();
    }
    AircraftController.prototype.init_ = function () {
        this.renderLoop_();
        createSpecsCarousel(this.element_);
        createTabCarousels(this.element_);
        new HighlightCarousel(this.element_);
    };
    AircraftController.prototype.updateActiveSlideOnBase_ = function () {
        var _this = this;
        var activeSlideIndex = this.scrollCarouselController_.getScrollCarousel().getActiveSlideIndex();
        var activeCssClass = generatedCssClasses.get(activeSlideIndex);
        var slideCount = this.scrollCarouselController_.getScrollCarousel().getSlides().length;
        var elementCssClasses = generateFilledArray(slideCount, function (i) { return generatedCssClasses.get(i); });
        renderLoop.mutate(function () {
            elementCssClasses.forEach(function (cssClass) {
                toggleCssClass(_this.element_, cssClass, cssClass === activeCssClass);
            });
        });
    };
    AircraftController.prototype.renderLoop_ = function () {
        var _this = this;
        renderLoop.measure(function () {
            renderLoop.cleanup(function () { return _this.renderLoop_(); });
            _this.updateActiveSlideOnBase_();
        });
    };
    AircraftController.$inject = ['$element'];
    return AircraftController;
}());
export { AircraftController };
