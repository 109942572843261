import { EasingFunction } from 'toolbox/utils/math/easing-function';
import { NumericRange } from 'toolbox/utils/math/numeric-range';
import { ScrollEffect } from 'toolbox/components/scroll-effect/base';
import { Tween } from 'toolbox/components/scroll-effect/effects/tween/tween';
import { addClassIfMissing } from 'toolbox/utils/dom/class/add-class-if-missing';
import { removeClassIfPresent } from 'toolbox/utils/dom/class/remove-class-if-present';
import { BreakpointCheck } from "../../ts/breakpoint-check";
var CssClass;
(function (CssClass) {
    CssClass["FULLY_REVEALED"] = "full-peeking-section--fully-revealed";
    CssClass["ARROW"] = "full-peeking-section__down-arrow";
    CssClass["TRANSLATION_BOX"] = "full-peeking-section__translation-box";
})(CssClass || (CssClass = {}));
var SCALE_AMOUNT = .9;
var TRANSLATE_Y_OFFSET = (1 - SCALE_AMOUNT) / 2 * 100;
var PEEK_AMOUNT_GT_MD = 96;
var PEEK_AMOUNT_LTE_MD = 60;
var START_TRANSFORM_GT_MD = "translateY(calc(-" + TRANSLATE_Y_OFFSET + "% - " + PEEK_AMOUNT_GT_MD + "px));";
var START_TRANSFORM_LTE_MD = "translateY(calc(-" + TRANSLATE_Y_OFFSET + "% - " + PEEK_AMOUNT_LTE_MD + "px));";
var TRANSLATION_TWEEN_GT_MD = [
    [0, "transform: " + START_TRANSFORM_GT_MD],
    [1, "transform: translateY(0px);"],
];
var TRANSLATION_TWEEN_LTE_MD = [
    [0, "transform: " + START_TRANSFORM_LTE_MD],
    [1, "transform: translateY(0px);"],
];
var ARROW_TWEEN = [[0, 'opacity: 1;'], [1, 'opacity: 0;']];
function generateTweenConfig(element) {
    return {
        easingFunction: EasingFunction.EASE_OUT_SINE,
        styleTarget: element
    };
}
function generateScrollEffectOptions(element, translationBox, arrow, TRANSLATION_TWEEN) {
    return {
        startDistance: function () { return -window.innerHeight; },
        endDistance: 0,
        percentCallbacks: [
            [
                new NumericRange(0, .9),
                [function () { return removeClassIfPresent(element, CssClass.FULLY_REVEALED); }]
            ],
            [1, [function () { return addClassIfMissing(element, CssClass.FULLY_REVEALED); }]]
        ],
        effects: [
            new Tween(TRANSLATION_TWEEN, generateTweenConfig(translationBox)),
            new Tween(ARROW_TWEEN, generateTweenConfig(arrow)),
        ]
    };
}
var FullPeekingSectionController = (function () {
    function FullPeekingSectionController($element) {
        var element = $element[0];
        var arrow = element.querySelector("." + CssClass.ARROW);
        var translationBox = element.querySelector("." + CssClass.TRANSLATION_BOX);
        this.scrollEffects_ = [
            new ScrollEffect(element, Object.assign(generateScrollEffectOptions(element, translationBox, arrow, TRANSLATION_TWEEN_GT_MD), { condition: BreakpointCheck.GT_MD })),
            new ScrollEffect(element, Object.assign(generateScrollEffectOptions(element, translationBox, arrow, TRANSLATION_TWEEN_LTE_MD), { condition: BreakpointCheck.LTE_MD }))
        ];
    }
    FullPeekingSectionController.$inject = ['$element'];
    return FullPeekingSectionController;
}());
export { FullPeekingSectionController };
