var Spacing;
(function (Spacing) {
    Spacing[Spacing["SPACING_LG"] = 80] = "SPACING_LG";
})(Spacing || (Spacing = {}));
var Typography;
(function (Typography) {
    Typography[Typography["LINE_HEIGHT_LARGE"] = 60] = "LINE_HEIGHT_LARGE";
})(Typography || (Typography = {}));
var Title;
(function (Title) {
    Title[Title["TITLE_MARGIN_BOTTOM"] = 80] = "TITLE_MARGIN_BOTTOM";
    Title[Title["TITLE_LINE_HEIGHT"] = 60] = "TITLE_LINE_HEIGHT";
})(Title || (Title = {}));
export { Spacing, Title, Typography };
