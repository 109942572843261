import { DistanceFunction } from "toolbox/components/scroll-effect/distance-function";
import { getOffsetFromAncestorIgnoringSticky } from "toolbox/utils/dom/position/vertical/get-offset-from-ancestor-ignoring-sticky";
var bodymovinConfigs = new Map([
    ['delivery-gt-md', function (el) {
            return {
                getDistanceFunction: DistanceFunction.DOCUMENT_SCROLL,
                startDistance: function () { return getOffsetFromAncestorIgnoringSticky(el); },
                endDistance: function () { return getOffsetFromAncestorIgnoringSticky(el) + window.innerHeight; },
            };
        }],
    ['delivery-lte-md', function (el) {
            return {
                getDistanceFunction: DistanceFunction.DOCUMENT_SCROLL,
                startDistance: function () {
                    return getOffsetFromAncestorIgnoringSticky(el) -
                        (window.innerHeight * .5);
                },
                endDistance: function () {
                    return getOffsetFromAncestorIgnoringSticky(el) -
                        (window.innerHeight * .1);
                },
            };
        }],
]);
export { bodymovinConfigs };
