import { autoToggleCssClass } from "toolbox/utils/dom/class/auto-toggle-css-class";
import { renderLoop } from "toolbox/utils/render-loop";
var CssClass;
(function (CssClass) {
    CssClass["ARROW"] = "image--home-locations-drawer-arrow";
    CssClass["DRAWER"] = "container--home-locations-drawer";
    CssClass["DRAWER_ACTIVE"] = "container--home-locations-drawer-active";
    CssClass["NAV_CONTENT"] = "rolodex-carousel-nav__content";
    CssClass["NAV_CONTENT_DRAWER_ACTIVE"] = "rolodex-carousel-nav__content--drawer-active";
})(CssClass || (CssClass = {}));
var HomeLocationsController = (function () {
    function HomeLocationsController($element) {
        var _this = this;
        var element = $element[0];
        var arrow = element.querySelector("." + CssClass.ARROW);
        this.drawer_ = element.querySelector("." + CssClass.DRAWER);
        this.navContent_ = element.querySelector("." + CssClass.NAV_CONTENT);
        arrow.addEventListener('click', function () { return _this.toggleDrawer_(); });
    }
    HomeLocationsController.prototype.toggleDrawer_ = function () {
        var _this = this;
        renderLoop.measure(function () {
            autoToggleCssClass(_this.drawer_, CssClass.DRAWER_ACTIVE);
            autoToggleCssClass(_this.navContent_, CssClass.NAV_CONTENT_DRAWER_ACTIVE);
        });
    };
    HomeLocationsController.$inject = ['$element'];
    return HomeLocationsController;
}());
export { HomeLocationsController };
