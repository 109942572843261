var CssClass;
(function (CssClass) {
    CssClass["BASE"] = "aircraft";
    CssClass["SLIDES_CONTAINER"] = "aircraft__slides";
    CssClass["SLIDE"] = "aircraft__slide";
    CssClass["TAB_HEADER_CONTAINER"] = "aircraft__tab-headers";
    CssClass["TAB_HEADER"] = "eyebrow--aircraft-tab-header";
    CssClass["TAB_BODY_CONTAINER"] = "aircraft__tab-contents";
    CssClass["TAB_BODY"] = "paragraphs--aircraft-tab-body";
    CssClass["SPECS_SLIDES_CONTAINER"] = "aircraft__specs-and-stats-slides";
    CssClass["SPECS_SLIDE"] = "aircraft__specs-and-stats-slide";
    CssClass["SPECS_NAV"] = "aircraft__specs-and-stats-nav";
    CssClass["SPECS_NAV_DOT"] = "aircraft__specs-and-stats-nav-dot";
    CssClass["HIGHLIGHT_SLIDES_CONTAINER"] = "aircraft__highlights";
    CssClass["HIGHLIGHT_SLIDES_CONTAINER_VISIBLE"] = "aircraft__highlights--visible";
    CssClass["HIGHLIGHT_SLIDES_CONTAINER_MODAL_OPEN"] = "aircraft__highlights--modal-open";
    CssClass["HIGHLIGHT_SLIDE"] = "aircraft__highlight";
    CssClass["HIGHLIGHT_SLIDE_DOT"] = "aircraft__highlight-dot";
    CssClass["HIGHLIGHT_ARROW_HIDDEN"] = "aircraft__highlight-arrow--hidden";
    CssClass["HIGHLIGHT_ARROW_PREV"] = "aircraft__highlight-arrow--prev";
    CssClass["HIGHLIGHT_NUMBER_PREV"] = "aircraft__highlight-arrow-number--prev";
    CssClass["HIGHLIGHT_ARROW_NEXT"] = "aircraft__highlight-arrow--next";
    CssClass["HIGHLIGHT_NUMBER_NEXT"] = "aircraft__highlight-arrow-number--next";
    CssClass["HIGHLIGHT_ARROW_IMAGE"] = "image--aircraft-highlight-arrow";
    CssClass["HIGHLIGHT_CLOSE_BUTTON"] = "image--aircraft-highlight-close";
    CssClass["BACKGROUND_TEXT"] = "aircraft__background-text";
    CssClass["BACKGROUND_TEXT_CONTAINER"] = "aircraft__background-text-container";
    CssClass["IMAGE_AIRCRAFT"] = "aircraft__aircraft-fixed-image";
    CssClass["WINGSPAN_LINE"] = "aircraft__spec-line--wingspan";
    CssClass["LENGTH_LINE"] = "aircraft__spec-line--length";
    CssClass["WEIGHT_LINE"] = "aircraft__spec-line--weight";
    CssClass["SPEED_LINE"] = "aircraft__spec-line--speed";
    CssClass["DISTANCE_LINE"] = "aircraft__spec-line--distance";
    CssClass["PACKAGE_LINE"] = "aircraft__spec-line--package";
    CssClass["WINGSPAN_SPEC"] = "aircraft__spec--wingspan";
    CssClass["LENGTH_SPEC"] = "aircraft__spec--length";
    CssClass["WEIGHT_SPEC"] = "aircraft__spec--weight";
    CssClass["SPEED_SPEC"] = "aircraft__spec--speed";
    CssClass["DISTANCE_SPEC"] = "aircraft__spec--distance";
    CssClass["PACKAGE_SPEC"] = "aircraft__spec--package";
    CssClass["LINE_TO_SPEC_FROM_CENTER"] = "aircraft__line--to-spec-from-center";
    CssClass["LINE_TO_SPEC_FROM_CIRCLE"] = "aircraft__line--to-spec-from-circle";
    CssClass["VERTICAL_LINE_TO_WINGSPAN"] = "aircraft__line--to-wingspan";
    CssClass["DASHED_LINE"] = "aircraft__line--dashed";
    CssClass["ANGLED_LINE_FROM_CIRCLE"] = "aircraft__line--angle-from-circle";
    CssClass["DESKTOP_OUTER_CIRCLE"] = "aircraft__outer-circle--gt-lg";
    CssClass["MOBILE_OUTER_CIRCLE"] = "aircraft__outer-circle--lte-lg";
    CssClass["MOBILE_SPECS_AND_STATS_AIRCRAFT"] = "aircraft__specs-and-stats-aircraft";
})(CssClass || (CssClass = {}));
export { CssClass };
