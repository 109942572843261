var UTMFullScreenController = (function () {
    function UTMFullScreenController($element, $scope, $attrs) {
        this._$scope = $scope;
        this._$el = document.querySelector('.scroll-carousel');
        this._$attrs = $attrs;
        this._inviewClass = this._$attrs.inviewActiveClass || 'inview';
        this._inviewCallbackClass = this._$attrs.inviewCallbackClass || 'inview-cb-complete';
        this._inviewInitClass = this._$attrs.inviewInitClass || 'inview-init';
        this._removeClassOnExit = this._$attrs.inviewRemoveClassOnExit !== 'False';
        this._inviewEnterCallback = this._$attrs.inviewEnterCallback || null;
        this._inviewExitCallback = this._$attrs.inviewExitCallback || null;
        this._intersectOpts = {
            root: null,
            threshold: this._$attrs.inviewThreshold || 0.5
        };
        this._isMobile = window.matchMedia('(max-width: 1024px)');
        this._init();
    }
    UTMFullScreenController.prototype._init = function () {
        if (this._$el) {
            this._scatterdots();
        }
    };
    UTMFullScreenController.prototype._inview = function () {
        var _this = this;
        var observer = new IntersectionObserver(function (entries) {
            var entry = entries[0];
            var isInview = entry.isIntersecting;
            if (_this._inviewClass) {
                if (isInview) {
                    if (_this._inviewEnterCallback && !_this._$el.classList.contains(_this._inviewCallbackClass)) {
                        _this._inviewEnterCallback(_this._$el, _this._inviewCallbackClass);
                        _this._$el.classList.add(_this._inviewCallbackClass);
                    }
                    _this._$el.classList.add(_this._inviewInitClass);
                    _this._$el.classList.add(_this._inviewClass);
                }
                else if (!isInview && (_this._$el.classList.contains(_this._inviewClass) && _this._removeClassOnExit)) {
                    if (_this._inviewExitCallback && _this._$el.classList.contains(_this._inviewCallbackClass)) {
                        _this._inviewExitCallback && _this._inviewExitCallback(_this._$el, _this._inviewCallbackClass);
                        _this._$el.classList.remove(_this._inviewCallbackClass);
                    }
                    _this._$el.classList.remove(_this._inviewClass);
                }
            }
        }, this._intersectOpts);
        observer.observe(this._$el);
    };
    UTMFullScreenController.prototype._randInt = function (min, max) {
        return Math.floor((Math.random() * (max - min)) + min);
    };
    UTMFullScreenController.prototype._scatterdots = function () {
        var _this = this;
        var $maps = Array.from(document.querySelectorAll('.full-bleed--1'));
        var $dots = [];
        $maps.forEach(function ($map) {
            var dots = Array.from($map.querySelectorAll('.utm-map-dots'));
            dots.forEach(function (dot, i) {
                dot.style.animationDelay = 50 * i + "ms";
                $dots.push(dot);
            });
        });
        $dots.forEach(function ($dot, i) {
            var y_pos = (_this._isMobile.matches) ? _this._randInt(0, 72) : _this._randInt(0, 100);
            var x_pos = (_this._isMobile.matches) ? _this._randInt(0, 100) : _this._randInt(10, 65);
            $dot.style.top = y_pos + "%";
            $dot.style.left = x_pos + "%";
        });
    };
    UTMFullScreenController.$inject = ['$element', '$scope', '$attrs'];
    return UTMFullScreenController;
}());
export { UTMFullScreenController };
