import { Carousel } from "toolbox/components/carousel/carousel";
import { PhysicalSlide } from "toolbox/components/carousel/transitions/physical-slide";
var CssClass;
(function (CssClass) {
    CssClass["SLIDES_CONTAINER"] = "carousel__slides";
    CssClass["SLIDE"] = "carousel__slide";
    CssClass["LEFT_ARROW"] = "image--carousel-arrow-left";
    CssClass["RIGHT_ARROW"] = "image--carousel-arrow-right";
    CssClass["DOTS"] = "carousel__dots__dot";
})(CssClass || (CssClass = {}));
var CarouselController = (function () {
    function CarouselController($element) {
        console.log('Yo!');
        var element = $element[0];
        var elements = Array.from(element.querySelectorAll("." + CssClass.SLIDE));
        if (elements.length > 1) {
            var container = element.querySelector("." + CssClass.SLIDES_CONTAINER);
            var dots_1 = Array.from(element.querySelectorAll("." + CssClass.DOTS));
            dots_1.forEach(function (dot, i) { return dot.addEventListener('click', function () { return carousel_1.transitionToIndex(i); }); });
            var onSlideChange = function (carousel) {
                setTimeout(function () {
                    dots_1.forEach(function (dot, i) { return dot.classList.toggle(carousel.activeCssClass_, i === carousel.getActiveSlideIndex()); });
                }, 0);
            };
            var carousel_1 = new Carousel(container, elements, {
                transition: new PhysicalSlide(),
                onTransitionCallbacks: [onSlideChange]
            });
            var leftArrow = element.querySelector("." + CssClass.LEFT_ARROW);
            leftArrow.addEventListener('click', function () { return carousel_1.previous(); });
            var rightArrow = element.querySelector("." + CssClass.RIGHT_ARROW);
            rightArrow.addEventListener('click', function () { return carousel_1.next(); });
        }
    }
    CarouselController.$inject = ['$element'];
    return CarouselController;
}());
export { CarouselController };
