import { BreakpointCheck } from "./breakpoint-check";
var BreakpointCheckMap = new Map([
    ['GT_XS', BreakpointCheck.GT_XS],
    ['LTE_XS', BreakpointCheck.LTE_XS],
    ['GT_SM', BreakpointCheck.GT_SM],
    ['LTE_SM', BreakpointCheck.LTE_SM],
    ['GT_MD', BreakpointCheck.GT_MD],
    ['LTE_MD', BreakpointCheck.LTE_MD],
    ['GT_LG', BreakpointCheck.GT_LG],
    ['LTE_LG', BreakpointCheck.LTE_LG],
    ['GT_XL', BreakpointCheck.GT_XL],
    ['LTE_XL', BreakpointCheck.LTE_XL],
    ['GT_XXL', BreakpointCheck.GT_XXL],
    ['LTE_XXL', BreakpointCheck.LTE_XXL],
]);
export { BreakpointCheckMap };
