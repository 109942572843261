import { BreakpointCheckMap } from '../breakpoint-check-map';
import { setStyle } from 'toolbox/utils/dom/style/set-style';
import { styleStringToMap } from 'toolbox/utils/dom/style/style-string-to-map';
import { clearStyle } from 'toolbox/utils/dom/style/clear-style';
import { forEach } from 'toolbox/utils/iterable-iterator/for-each';
import { renderLoop } from 'toolbox/utils/render-loop';
import { WindowDimensions } from 'toolbox/utils/cached-vectors/window-dimensions';
import { DynamicDefaultMap } from 'toolbox/utils/map/dynamic-default';
var windowDimensions = WindowDimensions.getSingleton();
var Attribute;
(function (Attribute) {
    Attribute["PREFIX"] = "data-breakpoint-style-";
})(Attribute || (Attribute = {}));
var BreakpointStyleController = (function () {
    function BreakpointStyleController($element, $scope) {
        var _this = this;
        this.appliedStyles_ = new Set();
        this.element_ = $element[0];
        this.destroyed_ = false;
        this.hasRun_ = false;
        var suffixes = DynamicDefaultMap.usingFunction(function (attribute) {
            return attribute.name
                .slice(Attribute.PREFIX.length).replace('-', '_').toUpperCase();
        });
        var mapValues = Array.from(this.element_.attributes)
            .filter(function (attribute) {
            return attribute.name.slice(0, Attribute.PREFIX.length) ===
                Attribute.PREFIX;
        })
            .filter(function (attribute) {
            return BreakpointCheckMap.has(suffixes.get(attribute));
        })
            .map(function (attribute) {
            return [
                BreakpointCheckMap.get(suffixes.get(attribute)),
                _this.element_.getAttribute(attribute.name)
            ];
        });
        this.styles_ = new Map(mapValues);
        this.lastFinalStyle_ = null;
        window.addEventListener('resize', function () { return _this.update_(); });
        this.update_();
        $scope.$on('$destroy', function () { return _this.dispose_(); });
    }
    BreakpointStyleController.prototype.applyStyle = function (rawStyle) {
        var _this = this;
        var style;
        if (typeof rawStyle === 'undefined') {
            return;
        }
        else if (typeof rawStyle === 'string') {
            if (rawStyle === '') {
                return;
            }
            style = rawStyle;
        }
        else {
            console.warn('Unknown type provided to breakpoint-style', rawStyle);
        }
        forEach(styleStringToMap(style).entries(), function (_a) {
            var style = _a[0], value = _a[1];
            setStyle(_this.element_, style, value);
            _this.appliedStyles_.add(style);
        });
    };
    BreakpointStyleController.prototype.update_ = function () {
        var _this = this;
        renderLoop.mutate(function () {
            var finalStyle = Array.from(_this.styles_.entries())
                .reduce(function (finalStyle, _a) {
                var breakpointCheck = _a[0], style = _a[1];
                return breakpointCheck() ? finalStyle + "; " + style : finalStyle;
            }, '');
            if (finalStyle !== _this.lastFinalStyle_) {
                _this.lastFinalStyle_ = finalStyle;
                forEach(_this.appliedStyles_.values(), function (style) { return clearStyle(_this.element_, style); });
                _this.appliedStyles_.clear();
                _this.applyStyle(finalStyle);
            }
        });
    };
    BreakpointStyleController.prototype.dispose_ = function () { };
    BreakpointStyleController.$inject = ['$element', '$scope'];
    return BreakpointStyleController;
}());
var breakpointStyleDirective = function () {
    return {
        restrict: 'A',
        controller: BreakpointStyleController,
    };
};
export { breakpointStyleDirective };
