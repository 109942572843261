var CHECKBOXES = [
    'updates',
    'offers',
    'research',
    'notRelevant',
    'tooManyEmails',
    'dontRecall',
    'otherChecked',
];
var UnsubscribeFormController = (function () {
    function UnsubscribeFormController($scope, $element, $http) {
        this.loading = false;
        this.submitted = false;
        this.error = false;
        this.scope_ = $scope;
        this.element_ = $element[0];
        this.http_ = $http;
        var formEl = this.element_.querySelector('form');
        this.formEl_ = formEl;
    }
    UnsubscribeFormController.prototype.init = function () {
        this.initEmailFromUrl_();
        this.initOtherCheckbox_();
        this.setupGTM_();
    };
    UnsubscribeFormController.prototype.setupGTM_ = function () {
        var _this = this;
        var formStart = false;
        this.formEl_.addEventListener('change', function (e) {
            var eventAction = 'change';
            var eventCategory = 'form';
            var eventLabel = e.target.dataset && e.target.dataset.label ? e.target.dataset.label : e.target.name;
            if (!formStart) {
                formStart = true;
                _this.submitGTMEvent_({
                    category: eventCategory,
                    action: 'start',
                    event: 'formStart',
                    label: eventLabel
                });
            }
            _this.submitGTMEvent_({
                category: eventCategory,
                label: eventLabel,
                action: eventAction,
                event: 'formChange'
            });
        });
    };
    UnsubscribeFormController.prototype.submitGTMEvent_ = function (gtmEvent) {
        window.dataLayer.push(gtmEvent);
    };
    UnsubscribeFormController.prototype.initEmailFromUrl_ = function () {
        if (!window.URLSearchParams) {
            return;
        }
        var url = new URL(location.href);
        var emailParamValue = url.searchParams.get('email');
        if (emailParamValue) {
            var emailField = this.formEl_.querySelector('[name="email"]');
            if (emailField) {
                emailField.value = emailParamValue;
            }
        }
    };
    UnsubscribeFormController.prototype.initOtherCheckbox_ = function () {
        var parent = this.formEl_.querySelector('.form__checkbox-other');
        var checkboxField = parent.querySelector('input[type="checkbox"]');
        var textField = parent.querySelector('input[type="text"]');
        textField.addEventListener('focus', function () {
            checkboxField.checked = true;
        });
    };
    UnsubscribeFormController.prototype.submit = function ($event) {
        var _this = this;
        if ($event) {
            $event.preventDefault();
        }
        if (this.loading) {
            return;
        }
        var errorEls = this.formEl_.querySelectorAll('.form__col__error');
        errorEls.forEach(function (el) {
            el.style.display = 'none';
        });
        var checkboxGroups = this.formEl_.querySelectorAll('.form__col__checkboxes[required]');
        var hasError = false;
        checkboxGroups.forEach(function (checkboxGroup) {
            var checkboxes = checkboxGroup.querySelectorAll('input[type="checkbox"]');
            var hasCheckedValue = Array.from(checkboxes).some(function (checkbox) { return checkbox.checked; });
            if (!hasCheckedValue) {
                hasError = true;
                var errorEl = checkboxGroup.nextElementSibling;
                errorEl.style.display = 'block';
            }
        });
        if (hasError) {
            return;
        }
        this.loading = true;
        var formData = new FormData(this.formEl_);
        var payload = {};
        payload['email'] = formData.get('email');
        CHECKBOXES.forEach(function (key) {
            var formValue = formData.get(key);
            if (formValue === 'true') {
                payload[key] = true;
            }
            else if (formValue === 'false') {
                payload[key] = false;
            }
            if (key === 'otherChecked' && formValue === 'true') {
                payload['otherReason'] = formData.get('otherReason');
            }
        });
        this.http_.post(this.formEl_.action, JSON.stringify(payload), {
            headers: { 'Content-Type': 'application/json' },
        }).then(function (response) {
            _this.loading = false;
            if (response.status == 200) {
                _this.submitted = true;
            }
            _this.updateScope_();
        }, function () {
            _this.loading = false;
            _this.error = true;
        });
        this.submitGTMEvent_({
            category: 'form',
            action: 'submit',
            event: 'formSubmit',
            label: 'submit_button'
        });
    };
    UnsubscribeFormController.prototype.updateScope_ = function () {
        if (!this.scope_.$$phase) {
            this.scope_.$apply();
        }
    };
    UnsubscribeFormController.$inject = ['$scope', '$element', '$http'];
    return UnsubscribeFormController;
}());
export { UnsubscribeFormController };
