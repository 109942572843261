import { Safari } from "toolbox/utils/user-agent/browser/safari";
import { Scroll } from 'toolbox/utils/cached-vectors/scroll';
import { UserAgent } from "toolbox/utils/user-agent/user-agent";
import { renderLoop } from 'toolbox/utils/render-loop';
import { setStyle } from 'toolbox/utils/dom/style/set-style';
import { getSign } from "toolbox/utils/math/get-sign";
var IS_SAFARI = UserAgent.getBrowser() === Safari;
var CssClass;
(function (CssClass) {
    CssClass["NAV"] = "top-nav";
    CssClass["NAV_ACTIVE"] = "top-nav--active";
    CssClass["NAV_MENU"] = "top-nav__menu";
    CssClass["NAV_DRAWER"] = "top-nav__drawer";
    CssClass["NAV_DRAWER_ACTIVE"] = "top-nav__drawer--active";
    CssClass["NAV_CLOSE"] = "top-nav__close";
    CssClass["NAV_CLOSE_AREA"] = "top-nav__close-area";
    CssClass["BODY_NAV_ACTIVE"] = "navActive";
})(CssClass || (CssClass = {}));
var TopNavController = (function () {
    function TopNavController($element) {
        var element = $element[0];
        this.bodyEl_ = document.querySelector('body');
        this.navEl_ = element.querySelector("." + CssClass.NAV);
        this.navMenus_ =
            Array.from(element.querySelectorAll("." + CssClass.NAV_MENU));
        this.navDrawer_ = element.querySelector("." + CssClass.NAV_DRAWER);
        this.navClose_ = element.querySelector("." + CssClass.NAV_CLOSE);
        this.navCloseArea_ = element.querySelector("." + CssClass.NAV_CLOSE_AREA);
        this.lastScrollDeltaSign_ = 0;
        this.wasNavActive_ = false;
        this.scroll_ = Scroll.getSingleton();
        this.init_();
    }
    TopNavController.prototype.init_ = function () {
        var _this = this;
        this.navClose_.addEventListener('click', function () { return _this.closeDrawer_(); });
        this.navCloseArea_.addEventListener('click', function () { return _this.closeDrawer_(); });
        this.navMenus_.forEach(function (menu) { return menu.addEventListener('click', function () { return _this.openDrawer_(); }); });
        this.scrollLoop_();
    };
    TopNavController.prototype.isMobile = function (width, height) {
        var mobileWidth = window.matchMedia("(max-width: " + (width || '768px') + ")");
        var mobileHeight = window.matchMedia("(max-height: " + (height || '460px') + ")");
        return mobileWidth.matches || mobileHeight.matches;
    };
    TopNavController.prototype.openDrawer_ = function () {
        if (this.isMobile()) {
            this.bodyEl_.style.top = "-" + window.scrollY + "px";
            this.bodyEl_.classList.add(CssClass.BODY_NAV_ACTIVE);
        }
        this.navDrawer_.classList.add(CssClass.NAV_DRAWER_ACTIVE);
    };
    TopNavController.prototype.closeDrawer_ = function () {
        this.navDrawer_.classList.remove(CssClass.NAV_DRAWER_ACTIVE);
        if (this.isMobile()) {
            var scrollY_1 = this.bodyEl_.style.top;
            this.bodyEl_.classList.remove(CssClass.BODY_NAV_ACTIVE);
            this.bodyEl_.style.top = '';
            window.scrollTo(0, parseInt(scrollY_1 || '0') * -1);
        }
    };
    TopNavController.prototype.scrollLoop_ = function () {
        var _this = this;
        renderLoop.scrollMeasure(function () {
            renderLoop.scrollCleanup(function () { return _this.scrollLoop_(); });
            _this.adjustTopNavPosition_();
            _this.adjustTopNavColoring_();
        });
    };
    TopNavController.prototype.adjustTopNavPosition_ = function () {
        var _this = this;
        var deltaSign = getSign(this.scroll_.getDelta().getY());
        var scrollYPos = this.scroll_.getPosition().getY();
        if (deltaSign === 0) {
            return;
        }
        if (deltaSign !== this.lastScrollDeltaSign_ && (scrollYPos > 0)) {
            this.lastScrollDeltaSign_ = deltaSign;
            var y_1 = deltaSign < 0 ? 0 : -this.navEl_.offsetHeight;
            renderLoop.scrollMutate(function () {
                if (IS_SAFARI) {
                    setStyle(_this.navEl_, 'top', y_1 + "px");
                }
                else {
                    setStyle(_this.navEl_, 'transform', "translateY(" + y_1 + "px)");
                }
            });
        }
    };
    TopNavController.prototype.adjustTopNavColoring_ = function () {
        var _this = this;
        var position = this.scroll_.getPosition().getY();
        var threshold = window.innerHeight * .15;
        var isActive = position > threshold;
        if (isActive !== this.wasNavActive_) {
            renderLoop.scrollMutate(function () {
                if (isActive) {
                    _this.navEl_.classList.add(CssClass.NAV_ACTIVE);
                }
                else {
                    _this.navEl_.classList.remove(CssClass.NAV_ACTIVE);
                }
                _this.wasNavActive_ = isActive;
            });
        }
    };
    TopNavController.$inject = ['$element'];
    return TopNavController;
}());
export { TopNavController };
