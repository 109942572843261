import { CssClass } from "./css-class";
var specToLineMap = new Map([
    [CssClass.WINGSPAN_SPEC, CssClass.WINGSPAN_LINE],
    [CssClass.LENGTH_SPEC, CssClass.LENGTH_LINE],
    [CssClass.WEIGHT_SPEC, CssClass.WEIGHT_LINE],
    [CssClass.SPEED_SPEC, CssClass.SPEED_LINE],
    [CssClass.DISTANCE_SPEC, CssClass.DISTANCE_LINE],
    [CssClass.PACKAGE_SPEC, CssClass.PACKAGE_LINE]
]);
export { specToLineMap };
