import { Carousel } from "toolbox/components/carousel/carousel";
import { CarouselSyncManager } from "toolbox/components/carousel/sync-manager";
var CssClass;
(function (CssClass) {
    CssClass["TITLES_CONTAINER"] = "rolodex-carousel-nav__titles";
    CssClass["TITLE"] = "rolodex-carousel-nav__title";
})(CssClass || (CssClass = {}));
var RolodexCarouselNavController = (function () {
    function RolodexCarouselNavController($element) {
        var element = $element[0];
        var container = element.querySelector("." + CssClass.TITLES_CONTAINER);
        var titles = Array.from(container.querySelectorAll("." + CssClass.TITLE));
        this.carousel_ = new Carousel(container, titles, { allowLooping: false });
    }
    RolodexCarouselNavController.prototype.init = function (carouselToSyncWith) {
        CarouselSyncManager.getSingleton()
            .syncCarousels(carouselToSyncWith, this.carousel_);
    };
    RolodexCarouselNavController.$inject = ['$element'];
    return RolodexCarouselNavController;
}());
export { RolodexCarouselNavController };
