var BusinessesCarouselController = (function () {
    function BusinessesCarouselController() {
        this.selectedCategory = null;
        this.selectedCategory = null;
    }
    BusinessesCarouselController.prototype.selectCategory = function (category) {
        this.selectedCategory = category;
    };
    return BusinessesCarouselController;
}());
export { BusinessesCarouselController };
