import { Spacing, Title } from "../../ts/sass-vars";
var NEIGHBORHOOD_DRAWER_SLIDE_CONTENT_PADDING = Spacing.SPACING_LG;
var NEIGHBORHOOD_DRAWER_SLIDE_NEXT_IMAGE_PEEK_PERCENT = 5;
var NEIGHBORHOOD_DRAWER_SLIDE_NEXT_IMAGE_TRANSLATE_Y_PX = Title.TITLE_MARGIN_BOTTOM + NEIGHBORHOOD_DRAWER_SLIDE_CONTENT_PADDING +
    (Title.TITLE_LINE_HEIGHT * 2);
var SLIDE_START = "200vh - " + NEIGHBORHOOD_DRAWER_SLIDE_NEXT_IMAGE_TRANSLATE_Y_PX * 2 + "px - " +
    (NEIGHBORHOOD_DRAWER_SLIDE_NEXT_IMAGE_PEEK_PERCENT * 2 + "%");
var SECOND_SLIDE_START = "100vh - " + NEIGHBORHOOD_DRAWER_SLIDE_NEXT_IMAGE_TRANSLATE_Y_PX + "px - " +
    (NEIGHBORHOOD_DRAWER_SLIDE_NEXT_IMAGE_PEEK_PERCENT + "%");
var SLIDE_END = "-100vh + " + NEIGHBORHOOD_DRAWER_SLIDE_NEXT_IMAGE_TRANSLATE_Y_PX + "px + " +
    (NEIGHBORHOOD_DRAWER_SLIDE_NEXT_IMAGE_PEEK_PERCENT + "%");
var FIRST_SLIDE_CONTENT_KEYFRAMES = [
    [0, "transform: translateY(0px);"],
    [1, "transform: translateY(calc(" + SLIDE_END + "));"],
];
var SECOND_SLIDE_CONTENT_KEYFRAMES = [
    [0, "transform: translateY(calc(" + SECOND_SLIDE_START + "));"],
    [1, "transform: translateY(calc(" + SLIDE_END + "));"],
];
var END_SLIDE_CONTENT_KEYFRAMES = [
    [0, "transform: translateY(calc(" + SLIDE_START + "));"],
    [1, "transform: translateY(0px);"],
];
var CONTENT_KEYFRAMES = [
    [0, "transform: translateY(calc(" + SLIDE_START + "));"],
    [1, "transform: translateY(calc(" + SLIDE_END + "));"],
];
function isFirstSlide(slideElement) {
    return slideElement.parentElement.firstChild === slideElement;
}
function isSecondSlide(slideElement) {
    return slideElement.parentElement.firstChild.nextSibling === slideElement;
}
function isLastSlide(slideElement) {
    return slideElement.parentElement.lastChild === slideElement;
}
function getContentKeyframes(isFirst, isSecond, isLast) {
    if (isFirst) {
        return FIRST_SLIDE_CONTENT_KEYFRAMES;
    }
    else if (isSecond) {
        return SECOND_SLIDE_CONTENT_KEYFRAMES;
    }
    else if (isLast) {
        return END_SLIDE_CONTENT_KEYFRAMES;
    }
    else {
        return CONTENT_KEYFRAMES;
    }
}
var SECOND_START_DISTANCE = function () { return -window.innerHeight; };
var DEFAULT_START_DISTANCE = function () { return -window.innerHeight * 2; };
function getStartDistance(isFirst, isSecond) {
    if (isFirst) {
        return 0;
    }
    else if (isSecond) {
        return SECOND_START_DISTANCE;
    }
    else {
        return DEFAULT_START_DISTANCE;
    }
}
var DEFAULT_END_DISTANCE = function () { return window.innerHeight; };
function getEndDistance(isLast) {
    if (isLast) {
        return 0;
    }
    else {
        return DEFAULT_END_DISTANCE;
    }
}
var CssClass;
(function (CssClass) {
    CssClass["CONTENT"] = "neighborhood-drawer-slide__content";
    CssClass["SCROLL_CAROUSEL_SLIDE"] = "scroll-carousel__slide";
})(CssClass || (CssClass = {}));
var NeighborhoodDrawerSlideController = (function () {
    function NeighborhoodDrawerSlideController($element) {
        var element = $element[0];
        var slideElement = element.parentElement;
        var content = element.querySelector("." + CssClass.CONTENT);
        if (!slideElement.classList.contains(CssClass.SCROLL_CAROUSEL_SLIDE)) {
            throw new Error('neighborhood-drawer-slide is expected to be nested in a ScrollCarousel');
        }
        var isFirst = isFirstSlide(slideElement);
        var isSecond = isSecondSlide(slideElement);
        var isLast = isLastSlide(slideElement);
        var keyframes = getContentKeyframes(isFirst, isSecond, isLast);
    }
    NeighborhoodDrawerSlideController.$inject = ['$element'];
    return NeighborhoodDrawerSlideController;
}());
export { NeighborhoodDrawerSlideController };
