var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ScrollCarouselController } from "../scroll-carousel/scroll-carousel-controller";
import { Carousel } from "toolbox/components/carousel/carousel";
import { CarouselSyncManager } from "toolbox/components/carousel/sync-manager";
import { ScrollEffect } from "toolbox/components/scroll-effect/base";
import { DistanceFunction } from "toolbox/components/scroll-effect/distance-function";
import { Tween } from "toolbox/components/scroll-effect/effects/tween/tween";
import { renderLoop } from "toolbox/utils/render-loop";
import { isVisible } from "toolbox/utils/dom/position/vertical/is-visible";
var CssClass;
(function (CssClass) {
    CssClass["ARROW"] = "order-journey__down-arrow";
    CssClass["ARROW_HIDDEN"] = "order-journey__down-arrow--hidden";
    CssClass["ACTIVE"] = "active";
    CssClass["CONTAINER"] = "order-journey";
    CssClass["IMAGES"] = "order-journey__images";
    CssClass["IMAGE"] = "order-journey__image";
    CssClass["VIDEO"] = "order-journey__video";
    CssClass["CONTENTS"] = "order-journey__contents";
    CssClass["CONTENT"] = "order-journey__content";
    CssClass["NAV"] = "order-journey__nav";
    CssClass["NAV_ITEM"] = "order-journey__nav-item";
    CssClass["PACKAGE_IMAGE_CONTAINER"] = "order-journey__image-container--package";
    CssClass["PACKAGE_LINE"] = "image--order-journey-line";
    CssClass["TITLE"] = "order-journey__title";
    CssClass["TITLES_CONTAINER"] = "order-journey__titles";
})(CssClass || (CssClass = {}));
var OrderJourneyController = (function (_super) {
    __extends(OrderJourneyController, _super);
    function OrderJourneyController($element, slideContainerCssClass, slideCssClass) {
        if (slideContainerCssClass === void 0) { slideContainerCssClass = CssClass.IMAGES; }
        if (slideCssClass === void 0) { slideCssClass = CssClass.IMAGE; }
        var _this = _super.call(this, $element, slideContainerCssClass, slideCssClass) || this;
        _this.contentCarousel_ =
            OrderJourneyController.createContentCarousel_(_this.element_);
        _this.navCarousel_ =
            OrderJourneyController.createNavCarousel_(_this.element_, _this.contentCarousel_);
        _this.titleCarousel_ =
            OrderJourneyController.createTitleCarousel_(_this.element_);
        _this.handleInviewChange_();
        _this.lastVisibleStateWasVisible_ = false;
        _this.firstVideoHasPlayed_ = false;
        _this.getScrollCarousel()
            .onTransition(function (carousel) { return _this.manageVideos_(carousel); });
        _this.downArrow_ = _this.element_.querySelector("." + CssClass.ARROW);
        _this.lastActiveSlide_ = null;
        var packageImage = _this.element_.querySelector("." + CssClass.PACKAGE_IMAGE_CONTAINER);
        if (packageImage) {
            new ScrollEffect(packageImage, {
                condition: function () { return window.innerWidth > 1024; },
                startDistance: 0,
                endDistance: function () { return packageImage.offsetHeight / 2; },
                getDistanceFunction: DistanceFunction.DISTANCE_FROM_DOCUMENT_TOP,
                effects: [new Tween([
                        [0, 'transform: translateY(calc(-100vh - 17vw)); opacity: 0;'],
                        [0.05, 'opacity: 1;'],
                        [1, 'transform: translateY(0vh)'],
                    ], { styleTarget: packageImage.querySelector("." + CssClass.PACKAGE_LINE) })]
            });
        }
        CarouselSyncManager.getSingleton()
            .syncCarousels(_this.navCarousel_, _this.contentCarousel_, _this.titleCarousel_, _this.getScrollCarousel());
        return _this;
    }
    OrderJourneyController.createNavCarousel_ = function (element, mainCarousel) {
        var slides = Array.from(element.querySelectorAll("." + CssClass.NAV_ITEM));
        var container = element.querySelector("." + CssClass.NAV);
        var carousel = new Carousel(container, slides, { allowLooping: false });
        slides.forEach(function (slide, index) {
            slide.addEventListener('click', function () { return mainCarousel.transitionToIndex(index); });
        });
        return carousel;
    };
    OrderJourneyController.prototype.next = function () {
        this.scrollToSlideIndex(this.contentCarousel_.getActiveSlideIndex() + 1);
    };
    OrderJourneyController.createContentCarousel_ = function (element) {
        var slides = Array.from(element.querySelectorAll("." + CssClass.CONTENT));
        var container = element.querySelector("." + CssClass.CONTENTS);
        return new Carousel(container, slides, { allowLooping: false });
    };
    OrderJourneyController.createTitleCarousel_ = function (element) {
        var slides = Array.from(element.querySelectorAll("." + CssClass.TITLE));
        var container = element.querySelector("." + CssClass.TITLES_CONTAINER);
        return new Carousel(container, slides, { allowLooping: false });
    };
    OrderJourneyController.prototype.handleInviewChange_ = function () {
        var _this = this;
        renderLoop.scrollMeasure(function () {
            renderLoop.scrollCleanup(function () { return _this.handleInviewChange_(); });
            var container = document.getElementsByClassName(CssClass.CONTAINER)[0];
            if (isVisible(container) && !_this.lastVisibleStateWasVisible_) {
                _this.containerInView_();
                _this.lastVisibleStateWasVisible_ = true;
            }
            else if (!isVisible(container) && _this.lastVisibleStateWasVisible_) {
                _this.containerOutOfView_();
                _this.lastVisibleStateWasVisible_ = false;
            }
            var scrollJackCarousel = _this.getScrollCarousel();
            var activeSlide = scrollJackCarousel.getActiveSlide();
            if (activeSlide !== _this.lastActiveSlide_) {
                var lastSlide = scrollJackCarousel.getSlides().slice(-1)[0];
                if (lastSlide === activeSlide) {
                    _this.downArrow_.classList.add(CssClass.ARROW_HIDDEN);
                }
                else {
                    _this.downArrow_.classList.remove(CssClass.ARROW_HIDDEN);
                }
                _this.lastActiveSlide_ = activeSlide;
            }
        });
    };
    OrderJourneyController.prototype.containerInView_ = function () {
        if (!this.firstVideoHasPlayed_) {
            this.playFirstVideo_();
        }
    };
    OrderJourneyController.prototype.containerOutOfView_ = function () {
        this.stopAllVideos_();
    };
    OrderJourneyController.prototype.playFirstVideo_ = function () {
        var firstVideoElement = this.element_.querySelector("." + CssClass.VIDEO + " video");
        if (firstVideoElement) {
            firstVideoElement.load();
            var firstVideoElementPlay = firstVideoElement.play();
            firstVideoElementPlay.then(function () { }).catch(function (e) { });
            this.firstVideoHasPlayed_ = true;
        }
    };
    OrderJourneyController.prototype.manageVideos_ = function (carousel) {
        var activeVideoElement = carousel.lastActiveSlide_.querySelector("." + CssClass.VIDEO + " video");
        if (activeVideoElement) {
            this.stopAllVideos_();
            activeVideoElement.load();
            var activeVideoElementPlay = activeVideoElement.play();
            activeVideoElementPlay.then(function () { }).catch(function (e) { });
        }
    };
    OrderJourneyController.prototype.stopAllVideos_ = function () {
        var allVideoElements = this.element_.querySelectorAll("." + CssClass.VIDEO + " video");
        allVideoElements.forEach(function (videoElement) {
            videoElement.load();
            videoElement.pause();
            videoElement.currentTime = 0;
        });
    };
    OrderJourneyController.prototype.hasHorizontalScrollMode_ = function () {
        return false;
    };
    OrderJourneyController.prototype.allowLoopingWhenHorizontal_ = function () {
        return false;
    };
    OrderJourneyController.prototype.shouldScrollHorizontally_ = function () {
        return false;
    };
    OrderJourneyController.$inject = ['$element'];
    return OrderJourneyController;
}(ScrollCarouselController));
export { OrderJourneyController };
