import { getChildrenWithClass } from "../../ts/get-children-with-class";
import { getVisibleDistanceFromRootIgnoringSticky } from 'toolbox/utils/dom/position/vertical/get-visible-distance-from-root-ignoring-sticky';
import { SmoothScrollService } from 'toolbox/components/smooth-scroll/smooth-scroll-service';
import { SCROLL_ELEMENT } from 'toolbox/utils/dom/position/scroll-element';
import { BreakpointCheck } from "../../ts/breakpoint-check";
import { VerticalScrollCarousel } from 'toolbox/components/vertical-scroll-carousel/vertical-scroll-carousel';
import { Carousel } from 'toolbox/components/carousel/carousel';
import { CarouselSyncManager } from 'toolbox/components/carousel/carousel-sync-manager';
import { PhysicalSlide } from 'toolbox/components/carousel/transitions/physical-slide';
import { setStyle } from 'toolbox/utils/dom/style/set-style';
var CssClass;
(function (CssClass) {
    CssClass["BASE_HORIZONTAL_ON_LTE_MD"] = "scroll-carousel--horizontal-on-lte-md";
    CssClass["SLIDES_CONTAINER"] = "scroll-carousel__slides";
    CssClass["SLIDE"] = "scroll-carousel__slide";
})(CssClass || (CssClass = {}));
var ScrollCarouselController = (function () {
    function ScrollCarouselController($element, slideContainerCssClass, slideCssClass) {
        var _this = this;
        if (slideContainerCssClass === void 0) { slideContainerCssClass = CssClass.SLIDES_CONTAINER; }
        if (slideCssClass === void 0) { slideCssClass = CssClass.SLIDE; }
        var element = $element[0];
        var slides = getChildrenWithClass(element.querySelector("." + slideContainerCssClass), slideCssClass);
        this.element_ = element;
        this.scrollCarousel_ =
            VerticalScrollCarousel.fireAndForget(element, slides);
        if (this.hasHorizontalScrollMode_()) {
            this.horizontalCarousel_ =
                new Carousel(element, slides, {
                    allowLooping: this.allowLoopingWhenHorizontal_(),
                    transition: new PhysicalSlide()
                });
            CarouselSyncManager.getSingleton().syncCarousels(this.scrollCarousel_.getCarousel(), this.horizontalCarousel_);
            this.handleResize_();
            window.addEventListener('resize', function () { return _this.handleResize_(); });
        }
        else {
            this.horizontalCarousel_ = null;
        }
    }
    ScrollCarouselController.prototype.allowLoopingWhenHorizontal_ = function () {
        return true;
    };
    ScrollCarouselController.prototype.hasHorizontalScrollMode_ = function () {
        return this.element_.classList.contains(CssClass.BASE_HORIZONTAL_ON_LTE_MD);
    };
    ScrollCarouselController.prototype.shouldScrollHorizontally_ = function () {
        return !BreakpointCheck.GT_MD();
    };
    ScrollCarouselController.prototype.handleResize_ = function () {
        if (!this.shouldScrollHorizontally_()) {
            this.getHorizontalCarousel().disable();
            this.getHorizontalCarousel().getSlides()
                .forEach(function (slide) { return setStyle(slide, 'transform', ''); });
        }
        else {
            this.getHorizontalCarousel().enable();
        }
    };
    ScrollCarouselController.prototype.scrollToSlideIndex = function (slideIndex) {
        var slide = this.getScrollCarousel().getSlideByIndex(slideIndex);
        var distanceFromRoot = getVisibleDistanceFromRootIgnoringSticky(slide);
        var scrollTarget = distanceFromRoot + SCROLL_ELEMENT.scrollTop;
        if (!this.hasHorizontalScrollMode_() || BreakpointCheck.GT_MD()) {
            SmoothScrollService.getSingleton().scrollToY(scrollTarget);
        }
    };
    ScrollCarouselController.prototype.getHorizontalCarousel = function () {
        return this.horizontalCarousel_;
    };
    ScrollCarouselController.prototype.getScrollCarousel = function () {
        return this.scrollCarousel_.getCarousel();
    };
    ScrollCarouselController.$inject = ['$element'];
    return ScrollCarouselController;
}());
export { ScrollCarouselController };
