var recaptchaService = (function () {
    function recaptchaService(formEl) {
        this.initted = false;
        this._$formEl = formEl;
        this._$captchaEl = this._$formEl.querySelector('.g-recaptcha');
        this._sitekey = this._$formEl.getAttribute('data-recaptcha-sitekey');
        this._timer = 0;
    }
    recaptchaService.prototype.execute = function () {
        grecaptcha.execute();
    };
    recaptchaService.prototype.init = function (cb) {
        var _this = this;
        if (this.initted) {
            return;
        }
        if (this.isRecaptchaEnabled()) {
            var captcha_1 = grecaptcha.render(this._$captchaEl, {
                sitekey: this._sitekey,
                size: 'invisible',
                badge: 'bottomleft',
                callback: function () {
                    var token = grecaptcha.getResponse(captcha_1);
                    cb && cb(token, _this._timer);
                },
            });
            setTimeout(function () {
                var el = document.querySelector('.grecaptcha-badge iframe');
                el && el.setAttribute('tabindex', '2');
            });
            this.initted = true;
            this.execute();
        }
    };
    recaptchaService.prototype.isRecaptchaEnabled = function () {
        return !!(this._$captchaEl && this._sitekey);
    };
    recaptchaService.prototype.generateTimestamp = function () {
        var timestamp = function () {
            var response = document.getElementById('g-recaptcha-response');
            if (response == null || response.value.trim() == '') {
                var captchaSettings = document.getElementsByName('captcha_settings')[0];
                if (captchaSettings) {
                    var elems = JSON.parse(captchaSettings.value);
                    elems['ts'] = JSON.stringify(new Date().getTime());
                    captchaSettings.value = JSON.stringify(elems);
                }
            }
        };
        this._timer = setInterval(timestamp, 500);
    };
    return recaptchaService;
}());
export default recaptchaService;
