import tagService from './../services/tag-service';
var SocialShareController = (function () {
    function SocialShareController($element, $attrs) {
        this._$el = $element[0];
        this._$attrs = $attrs;
        this._$d = document;
        this._$w = window;
        this._tagService = new tagService();
        this._shareNetwork = this._$attrs.socialShare;
        this._title = encodeURIComponent(this._$d.title);
        this._twitterBaseURL = 'https://twitter.com/intent/tweet';
        this._facebookBaseURL = 'https://www.facebook.com/sharer/sharer.php';
        this._linkedinBaseURL = 'https://www.linkedin.com/shareArticle';
        this._canonicalURL = encodeURIComponent(this._tagService.getLinkValue('canonical'));
        if (this._shareNetwork) {
            this._init();
        }
    }
    SocialShareController.prototype._generateWindow = function (el, url, title) {
        var _this = this;
        el.addEventListener('click', function (e) {
            e.preventDefault();
            var win = _this._$w.open(url, title, _this._getWindowOptions());
            win.opener = null;
        });
    };
    SocialShareController.prototype._getWindowOptions = function () {
        var width = 500;
        var height = 350;
        var left = (this._$w.innerWidth / 2) - (width / 2);
        var top = (this._$w.innerHeight / 2) - (height / 2);
        return [
            'resizable,scrollbars,status',
            'height=' + height,
            'width=' + width,
            'left=' + left,
            'top=' + top,
        ].join();
    };
    SocialShareController.prototype._init = function () {
        this._createShareWindow(this._$el, this._shareNetwork);
    };
    SocialShareController.prototype._createShareWindow = function (el, social) {
        var shareUrl = '';
        var shareTitle = '';
        switch (social) {
            case 'facebook':
                shareUrl = this._facebookBaseURL + "?u=" + this._canonicalURL;
                shareTitle = 'Share On Facebook';
                break;
            case 'twitter':
                var via = this._tagService.getMetaValue('twitter:site');
                shareUrl = this._twitterBaseURL + "?url=" + this._canonicalURL + "&text=" + this._title + "&via=" + via;
                shareTitle = 'Share On Twitter';
                break;
            case 'linkedin':
                shareUrl = this._linkedinBaseURL + "?mini=true&url=" + this._canonicalURL + "&title=" + this._title;
                shareTitle = 'Share On LinkedIn';
                break;
            default: break;
        }
        this._$el.href = shareUrl;
        this._generateWindow(this._$el, shareUrl, shareTitle);
    };
    SocialShareController.$inject = ['$element', '$attrs'];
    return SocialShareController;
}());
var socialShareDirective = function () {
    return {
        restrict: 'A',
        controller: SocialShareController,
    };
};
export { socialShareDirective };
