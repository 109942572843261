import recaptchaService from './services/recaptcha-service';
var IFrameForm = (function () {
    function IFrameForm(form, iframe) {
        this.form_ = form;
        this.iframe_ = iframe;
        this.form_.addEventListener('submit', function (e) { return e.preventDefault(); });
    }
    IFrameForm.prototype.getElement = function () {
        return this.form_;
    };
    IFrameForm.prototype.submit = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.form_.target == '_blank' || _this.form_.target == '_self') {
                window.setTimeout(function () {
                    resolve();
                }, 100);
            }
            else {
                _this.iframe_.addEventListener('load', function () { return resolve(); });
            }
            _this.form_.submit();
        });
    };
    return IFrameForm;
}());
var IFrameFormController = (function () {
    function IFrameFormController($scope, $element, $http) {
        this.loading = false;
        this.submitted = false;
        this.scope_ = $scope;
        this.element_ = $element[0];
        this.http_ = $http;
        this.formEl_ = this.element_.querySelector('form');
        this.iframeEl_ = this.element_.querySelector('iframe');
        this.form_ = new IFrameForm(this.formEl_, this.iframeEl_);
        this.recaptchaService = new recaptchaService(this.formEl_);
    }
    IFrameFormController.prototype.init = function () {
        this.setupGTM_();
        this.setDynamicRequiredFields();
    };
    IFrameFormController.prototype.setDynamicRequiredFields = function () {
        var addressFields = [
            this.formEl_['street'],
            this.formEl_['city'],
            this.formEl_['state'],
            this.formEl_['zip']
        ];
        var allFieldsArePresent = addressFields.every(function (e) { return e; });
        if (addressFields.length == 4 && allFieldsArePresent) {
            var toggleRequiredFields_1 = function (e) {
                var inputValue = e.target.value;
                var allFieldsAreEmpty = addressFields.every(function (e) { return (e.value == null || e.value.length == 0); });
                if (inputValue !== null && inputValue.length > 0) {
                    addressFields.forEach(function (field) {
                        field.required = true;
                        field.placeholder = (field.placeholder.substr(-1) !== '*') ? field.placeholder + '*' : field.placeholder;
                    });
                }
                if (allFieldsAreEmpty) {
                    addressFields.forEach(function (field) {
                        field.required = false;
                        field.placeholder = field.placeholder.replace('*', '');
                    });
                }
            };
            addressFields.forEach(function (field) {
                field.addEventListener('change', function (e) { return toggleRequiredFields_1(e); });
            });
        }
    };
    IFrameFormController.prototype.setupGTM_ = function () {
        var _this = this;
        var formStart = false;
        var eventCategory = 'form';
        var eventAction = 'change';
        var eventName = 'formChange';
        this.formEl_.addEventListener('change', function (e) {
            var eventLabel = e.target.dataset && e.target.dataset.label ? e.target.dataset.label : e.target.name;
            if (!formStart) {
                formStart = true;
                eventAction = 'start';
                eventName = 'formStart';
            }
            else {
                eventAction = 'change';
                eventName = 'formChange';
            }
            _this.submitGTMEvent_({
                category: eventCategory,
                label: eventLabel,
                action: eventAction,
                event: eventName
            });
        });
    };
    IFrameFormController.prototype.submitGTMEvent_ = function (gtmEvent) {
        window.dataLayer.push(gtmEvent);
    };
    IFrameFormController.prototype.submit = function ($event) {
        var _this = this;
        if ($event) {
            $event.preventDefault();
        }
        if (this.loading) {
            return;
        }
        this.loading = true;
        if (this.recaptchaService.isRecaptchaEnabled()) {
            this.recaptchaService.init(this.onFormSubmit.bind(this));
        }
        else {
            this.form_.submit().then(function () {
                console.log('Form Submitted');
                _this.loading = false;
                _this.submitted = true;
                _this.updateHash('#thank-you');
                _this.updateScope_();
            });
            this.submitGTMEvent_({
                category: 'form',
                action: 'submit',
                event: 'formSubmit',
                label: 'submit_button'
            });
        }
    };
    IFrameFormController.prototype.updateScope_ = function () {
        if (!this.scope_.$$phase) {
            this.scope_.$apply();
        }
    };
    IFrameFormController.prototype.onFormSubmit = function (token, timestamp) {
        var _this = this;
        this.http_.post(this.formEl_.getAttribute('data-recaptcha-url'), {
            recaptcha_response: token
        })
            .then(function (res) {
            if (res.status !== 200 || !res.data.success) {
                throw new Error('reCAPTCHA validation failed: ' + res.data['error-codes']);
            }
            console.log('reCAPTCHA passed');
            _this.form_.submit().then(function () {
                _this.loading = false;
                _this.submitted = true;
                if (timestamp !== 0)
                    clearInterval(timestamp);
                _this.updateHash('#thank-you');
                _this.updateScope_();
            });
        })
            .catch(function (error) {
            console.error(error);
        });
        this.submitGTMEvent_({
            category: 'form',
            action: 'submit',
            event: 'formSubmit',
            label: 'submit_button'
        });
    };
    IFrameFormController.prototype.updateHash = function (url) {
        history.replaceState(null, null, "" + url);
        this.submitGTMEvent_({
            'event': 'pageView',
            'pagePath': document.location.pathname,
            'pageTitle': document.title,
            'category': 'page',
            'action': 'view',
            'label': url
        });
    };
    IFrameFormController.$inject = ['$scope', '$element', '$http'];
    return IFrameFormController;
}());
export { IFrameForm, IFrameFormController };
