var InviewController = (function () {
    function InviewController($element, $scope, $attrs) {
        this._$scope = $scope;
        this._$el = $element[0];
        this._$attrs = $attrs;
        this._inviewClass = this._$attrs.inviewActiveClass || 'inview';
        this._inviewCallbackClass = this._$attrs.inviewCallbackClass || 'inview-cb-complete';
        this._inviewInitClass = this._$attrs.inviewInitClass || 'inview-init';
        this._removeClassOnExit = this._$attrs.inviewRemoveClassOnExit !== 'False';
        this._inviewEnterCallback = this._$attrs.inviewEnterCallback || null;
        this._inviewExitCallback = this._$attrs.inviewExitCallback || null;
        this._intersectOpts = {
            root: (this._$attrs.inviewTargetElement) ? document.querySelector(this._$attrs.inviewTargetElement) : null,
            threshold: this._$attrs.inviewThreshold || 0.5
        };
        this._init();
    }
    InviewController.prototype._init = function () {
        var _this = this;
        if (this._$el) {
            var observer = new IntersectionObserver(function (entries) {
                var entry = entries[0];
                var isInview = entry.isIntersecting;
                if (_this._inviewClass) {
                    if (isInview) {
                        if (_this._inviewEnterCallback && !_this._$el.classList.contains(_this._inviewCallbackClass)) {
                            _this._inviewEnterCallback(_this._$el, _this._inviewCallbackClass);
                            _this._$el.classList.add(_this._inviewCallbackClass);
                        }
                        _this._$el.classList.add(_this._inviewInitClass);
                        _this._$el.classList.add(_this._inviewClass);
                    }
                    else if (!isInview && (_this._$el.classList.contains(_this._inviewClass) && _this._removeClassOnExit)) {
                        if (_this._inviewExitCallback && _this._$el.classList.contains(_this._inviewCallbackClass)) {
                            _this._inviewExitCallback && _this._inviewExitCallback(_this._$el, _this._inviewCallbackClass);
                            _this._$el.classList.remove(_this._inviewCallbackClass);
                        }
                        _this._$el.classList.remove(_this._inviewClass);
                    }
                }
            }, this._intersectOpts);
            observer.observe(this._$el);
        }
    };
    InviewController.$inject = ['$element', '$scope', '$attrs'];
    return InviewController;
}());
var inviewDirective = function () {
    return {
        restrict: 'A',
        controller: InviewController,
    };
};
export { inviewDirective };
