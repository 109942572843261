import { Carousel } from "toolbox/components/carousel/carousel";
import { CssClass } from "./css-class";
import { CarouselSyncManager } from "toolbox/components/carousel/sync-manager";
import { generateFilledArray } from "toolbox/utils/array/generate-filled-array";
import { DynamicDefaultMap } from "toolbox/utils/map/dynamic-default";
import { renderLoop } from "toolbox/utils/render-loop";
import { toggleCssClass } from "toolbox/utils/dom/class/toggle-css-class";
var generatedCssClasses = DynamicDefaultMap.usingFunction(function (i) { return CssClass.BASE + "--safety-" + i; });
function createTabHeadersCarousel(element) {
    var container = element.querySelector("." + CssClass.TAB_HEADER_CONTAINER);
    var slides = Array.from(element.querySelectorAll("." + CssClass.TAB_HEADER));
    var carousel = new Carousel(container, slides, { onTransitionCallbacks: [
            function (carousel) {
                var elementCssClasses = generateFilledArray(carousel.getSlides().length, function (i) { return generatedCssClasses.get(i); });
                var activeSlideIndex = carousel.getActiveSlideIndex();
                var activeCssClass = generatedCssClasses.get(activeSlideIndex);
                renderLoop.mutate(function () {
                    elementCssClasses.forEach(function (cssClass) { return toggleCssClass(element, cssClass, cssClass === activeCssClass); });
                });
            }
        ] });
    slides.forEach(function (slide, index) { return slide.addEventListener('click', function () { return carousel.transitionToIndex(index); }); });
    return carousel;
}
function createTabBodiesCarousel(element) {
    var container = element.querySelector("." + CssClass.TAB_BODY_CONTAINER);
    var slides = Array.from(element.querySelectorAll("." + CssClass.TAB_BODY));
    return new Carousel(container, slides);
}
function createTabCarousels(element) {
    var headers = createTabHeadersCarousel(element);
    var bodies = createTabBodiesCarousel(element);
    CarouselSyncManager.getSingleton().syncCarousels(headers, bodies);
    return [headers, bodies];
}
export { createTabCarousels };
