import LazyLoad from 'vanilla-lazyload';
var LazyLoadController = (function () {
    function LazyLoadController() {
        new LazyLoad({
            elements_selector: 'img, .lazy-image',
            threshold: 500
        });
    }
    return LazyLoadController;
}());
export { LazyLoadController };
