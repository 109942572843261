var tagService = (function () {
    function tagService() {
        this._$el = document;
    }
    tagService.prototype._getElementAttributeValue = function (type, key) {
        type = (type === 'link') ? 'link' : 'meta';
        var value = '';
        var attribute_1 = (type === 'link') ? 'rel' : 'name';
        var attribute_2 = (type === 'link') ? 'href' : 'content';
        var els = Array.from(this._$el.getElementsByTagName(type));
        els.forEach(function (el) {
            if (el.getAttribute(attribute_1) === key) {
                value = el.getAttribute(attribute_2);
            }
        });
        return value;
    };
    tagService.prototype.getLinkValue = function (key) {
        return this._getElementAttributeValue('link', key);
    };
    tagService.prototype.getMetaValue = function (key) {
        return this._getElementAttributeValue('meta', key);
    };
    return tagService;
}());
export default tagService;
