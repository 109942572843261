var MagicValue;
(function (MagicValue) {
    MagicValue["ALL_DEPARTMENTS"] = "All departments";
    MagicValue["ALL_LOCATIONS"] = "All locations";
})(MagicValue || (MagicValue = {}));
var JobBoardController = (function () {
    function JobBoardController($element) {
        this.element_ = $element[0];
        this.department = MagicValue.ALL_DEPARTMENTS;
        this.location = MagicValue.ALL_LOCATIONS;
    }
    JobBoardController.prototype.isShowingAllDepartments = function () {
        return this.department === MagicValue.ALL_DEPARTMENTS;
    };
    JobBoardController.prototype.isShowingAllLocations = function () {
        return this.location === MagicValue.ALL_LOCATIONS;
    };
    JobBoardController.prototype.shouldShowJob = function (location) {
        return this.isShowingAllLocations() || this.location === location;
    };
    JobBoardController.prototype.shouldShowDepartment = function (department) {
        return (this.isShowingAllDepartments() ||
            (department === this.department && this.departmentHasJobForCurrentLocation_(department)));
    };
    JobBoardController.prototype.getDepartmentElement_ = function (department) {
        return this.element_.querySelector("[data-department=\"" + department + "\"]");
    };
    JobBoardController.prototype.departmentHasJobForCurrentLocation_ = function (department) {
        if (this.isShowingAllLocations()) {
            return true;
        }
        return !!this.getDepartmentElement_(department).querySelector("[data-location=\"" + this.location + "\"]");
    };
    JobBoardController.$inject = ['$element'];
    return JobBoardController;
}());
export { JobBoardController };
