import { Carousel } from "toolbox/components/carousel/carousel";
import { CarouselSyncManager } from "toolbox/components/carousel/carousel-sync-manager";
import { generateFilledArray } from "toolbox/utils/array/generate-filled-array";
import { renderLoop } from "toolbox/utils/render-loop";
var CssClass;
(function (CssClass) {
    CssClass["DOT"] = "carousel-dot-nav__dot";
})(CssClass || (CssClass = {}));
function createNavDot_(element, index, carousel) {
    var dot = document.createElement('li');
    dot.addEventListener('click', function () { return carousel.transitionToIndex(index); });
    renderLoop.mutate(function () {
        dot.classList.add(CssClass.DOT);
        element.appendChild(dot);
    });
    return dot;
}
var CarouselDotNavController = (function () {
    function CarouselDotNavController($element) {
        this.carousel_ = null;
        this.navCarousel_ = null;
        this.element_ = $element[0];
        this.dots_ = [];
    }
    CarouselDotNavController.prototype.init = function (carousel) {
        var _this = this;
        this.carousel_ = carousel;
        this.dots_ =
            generateFilledArray(this.carousel_.getSlides().length, function (i) { return createNavDot_(_this.element_, i, carousel); });
        this.navCarousel_ = new Carousel(this.element_, this.dots_);
        CarouselSyncManager.getSingleton()
            .syncCarousels(this.carousel_, this.navCarousel_);
    };
    CarouselDotNavController.$inject = ['$element'];
    return CarouselDotNavController;
}());
export { CarouselDotNavController };
