import uri from "airkit/utils/uri";
var LegalNavController = (function () {
    function LegalNavController() {
        this.isExpanded = false;
        if (uri.hasParameter('expanded')) {
            this.isExpanded = true;
        }
    }
    return LegalNavController;
}());
export { LegalNavController };
