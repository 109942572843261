import { ScrollEffect } from "toolbox/components/scroll-effect/base";
import { Tween } from "toolbox/components/scroll-effect/effects/tween/tween";
import { DistanceFunction } from "toolbox/components/scroll-effect/distance-function";
import { getMatchingParentElement } from "toolbox/utils/dom/ancestry/get-matching-parent-element";
import { getOffsetFromAncestorIgnoringSticky } from "toolbox/utils/dom/position/vertical/get-offset-from-ancestor-ignoring-sticky";
var Attribute;
(function (Attribute) {
    Attribute["CONTAINER"] = "data-scroll-tween-container";
})(Attribute || (Attribute = {}));
function isScrollTweenContainer(element) {
    return element.hasAttribute(Attribute.CONTAINER);
}
var ScrollTweenController = (function () {
    function ScrollTweenController($element, $scope) {
        var _this = this;
        this.effect_ = ScrollTweenController.generateEffect_($element[0]);
        $scope.$on('$destroy', function () { return _this.dispose_(); });
    }
    ScrollTweenController.generateEffect_ = function (element) {
        var scrollTweenContainer = getMatchingParentElement(element, isScrollTweenContainer);
        var keyframes = (JSON.parse(element.dataset.scrollTweenFrames)
            .map(function (_a) {
            var keyframe = _a[0], style = _a[1];
            return [keyframe / 100, style];
        }));
        var tween = new Tween(keyframes, { styleTarget: element });
        return new ScrollEffect(scrollTweenContainer, {
            effects: [tween],
            getDistanceFunction: DistanceFunction.DOCUMENT_SCROLL,
            startDistance: function () { return getOffsetFromAncestorIgnoringSticky(scrollTweenContainer); },
            endDistance: function () {
                return getOffsetFromAncestorIgnoringSticky(scrollTweenContainer) +
                    scrollTweenContainer.offsetHeight - window.innerHeight;
            },
        });
    };
    ScrollTweenController.prototype.dispose_ = function () {
        this.effect_.destroy();
    };
    ScrollTweenController.$inject = ['$element', '$scope'];
    return ScrollTweenController;
}());
var scrollTweenDirective = function () {
    return {
        restrict: 'A',
        controller: ScrollTweenController,
    };
};
export { scrollTweenDirective };
