var TEL_REGEXES = [
    /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
    /^((([\+][\s]{0,1})|([0]{2}[\s-]{0,1}))([358]{3})([\s-]{0,1})|([0]{1}))(([1-9]{1}[0-9]{0,1})([\s-]{0,1})([0-9]{2,4})([\s-]{0,1})([0-9]{2,4})([\s-]{0,1}))([0-9]{0,3}){1}$/,
    /^(?:\+?(1))? ?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
];
var RoleController = (function () {
    function RoleController($element, $http, $scope, $rootScope) {
        var _this = this;
        this.getSource = function () {
            var uri = new URL(location.href);
            var searchParams = uri.searchParams;
            if (!searchParams) {
                return null;
            }
            return searchParams.get('utm_source') || searchParams.get('gh_src');
        };
        this.recaptchaCallback = function () {
        };
        this.initAutocomplete = function () {
            var addressEl = document.querySelector('input[name=location]');
            if (!addressEl) {
                return;
            }
            var latEl = document.querySelector('input[name=latitude]');
            var lngEl = document.querySelector('input[name=longitude]');
            var options = {
                'types': ['address']
            };
            var ac = new google.maps.places.Autocomplete(addressEl, options);
            ac.addListener('place_changed', function (e) {
                var place = ac.getPlace();
                if (!place) {
                    return;
                }
                var lat = place['geometry']['location']['lat']();
                var lng = place['geometry']['location']['lng']();
                latEl.value = '' + lat;
                lngEl.value = '' + lng;
            });
        };
        this.element_ = $element[0];
        this.scope_ = $scope;
        this.http_ = $http;
        this.isError = false;
        this.isSubmitted = false;
        this.isLoading = false;
        this.numEducationRows = 1;
        this.form_ = this.element_.querySelector('form');
        this.initFileInputEls_();
        window.setTimeout(function () {
            _this.initAutocomplete();
        }, 20);
    }
    RoleController.prototype.init = function () {
        this.setupGTM_();
    };
    RoleController.prototype.setupGTM_ = function () {
        var _this = this;
        var formStart = false;
        this.form_.addEventListener('change', function (e) {
            var eventAction = 'change';
            var eventCategory = 'form';
            var eventLabel = e.target.dataset && e.target.dataset.label ? e.target.dataset.label : e.target.name;
            if (eventLabel.indexOf('question_') != -1) {
                if (e.target.placeholder) {
                    eventLabel = e.target.placeholder
                        .toString().toLowerCase()
                        .replace(/\s+/g, '_')
                        .replace(/[^\w\-]+/g, '')
                        .trim();
                }
                else if (e.target.type && e.target.value) {
                    eventLabel = e.target.type + '_' + e.target.value;
                }
            }
            if (!formStart) {
                formStart = true;
                _this.submitGTMEvent_({
                    category: eventCategory,
                    action: 'start',
                    event: 'formStart',
                    label: eventLabel
                });
            }
            _this.submitGTMEvent_({
                category: eventCategory,
                label: eventLabel,
                action: eventAction,
                event: 'formChange'
            });
        });
    };
    RoleController.prototype.submitGTMEvent_ = function (gtmEvent) {
        window.dataLayer.push(gtmEvent);
    };
    RoleController.prototype.initFileInputEls_ = function () {
        var _this = this;
        var fileInputEls = this.element_.querySelectorAll('input[type=file]');
        [].forEach.call(fileInputEls, function (el) {
            var labelEl = _this.element_.querySelector('label[for="' + el.id + '"]');
            el.addEventListener('change', function (e) {
                var enabled = Boolean(el.files && el.value);
                if (enabled) {
                    var fileName = el.value.split('/').pop();
                    fileName = fileName.split('\\').pop();
                    labelEl.setAttribute('data-label', labelEl.textContent);
                    labelEl.textContent = fileName;
                }
                else if (labelEl.getAttribute('data-label')) {
                    labelEl.textContent = labelEl.getAttribute('data-label');
                }
                var className = 'career__form__question__field__file--enabled';
                labelEl.classList.toggle(className, enabled);
            });
        });
    };
    RoleController.prototype.isFieldValid = function (field) {
        if (field.type === 'checkbox') {
            return field.checked;
        }
        if (field.name === 'location') {
            var isValid = false;
            if (field.value) {
                var latEl = this.form_.querySelector('input[name=latitude]');
                var lngEl = this.form_.querySelector('input[name=longitude]');
                isValid = Boolean((latEl && latEl.value) && (lngEl && lngEl.value));
            }
            field.classList.toggle('form-field-invalid', !isValid);
            return isValid;
        }
        if (field.type === 'tel') {
            var tel_1 = field.value;
            var isValid = Boolean(tel_1) && TEL_REGEXES.some(function (pattern) { return tel_1.match(pattern); });
            field.classList.toggle('form-field-invalid', !isValid);
            return isValid;
        }
        if (field.type === 'file') {
            var file = field.value;
            var isValid = Boolean(file);
            field.classList.toggle('form-field-invalid', !isValid);
            return isValid;
        }
        return Boolean(field.value);
    };
    RoleController.prototype.isFormValid = function () {
        var _this = this;
        var selector = "[required]";
        var requiredFields = this.form_.querySelectorAll(selector);
        var isValid = true;
        Array.from(requiredFields).forEach(function (field) {
            if (!_this.isFieldValid(field)) {
                isValid = false;
            }
        });
        return isValid;
    };
    RoleController.prototype.toggleRequiredError = function (enabled) {
        this.element_.classList.toggle('form--required-error-visible', enabled);
        this.setError(enabled);
    };
    RoleController.prototype.validate = function () {
        this.toggleRequiredError(!this.isFormValid());
    };
    RoleController.prototype.submit = function ($event) {
        var _this = this;
        if ($event) {
            $event.preventDefault();
        }
        if (this.isLoading) {
            return;
        }
        var valid = this.isFormValid();
        this.toggleRequiredError(!valid);
        if (!valid) {
            return;
        }
        this.isLoading = true;
        var postUrl = this.form_.getAttribute('data-form-url');
        var data = new FormData(this.form_);
        this.http_.post(postUrl, data, {
            headers: { 'Content-Type': undefined }
        }).then(function () {
            _this.setSubmitted();
        }, function () {
            _this.setError(true);
        });
        this.submitGTMEvent_({
            category: 'form',
            action: 'submit',
            event: 'formSubmit',
            label: 'submit_button'
        });
    };
    RoleController.prototype.setError = function (isError) {
        this.isError = isError;
        this.isLoading = false;
    };
    RoleController.prototype.setSubmitted = function () {
        this.isError = false;
        this.isLoading = false;
        this.isSubmitted = true;
    };
    RoleController.$inject = [
        '$element',
        '$http',
        '$scope',
        '$rootScope',
    ];
    return RoleController;
}());
export { RoleController };
