var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Carousel } from "toolbox/components/carousel/carousel";
import { CarouselSyncManager } from "toolbox/components/carousel/sync-manager";
import { ScrollCarouselController } from "../scroll-carousel/scroll-carousel-controller";
var CssClass;
(function (CssClass) {
    CssClass["APP_CHAPTERS"] = "opensky-app__chapters";
    CssClass["APP_CHAPTER"] = "opensky-app__chapter";
    CssClass["NAV"] = "opensky-app__nav";
    CssClass["NAV_ITEM"] = "opensky-app__nav-item";
})(CssClass || (CssClass = {}));
function createNavCarousel(element, mainCarousel) {
    var container = element.querySelector("." + CssClass.NAV);
    var navItems = Array.from(element.querySelectorAll("." + CssClass.NAV_ITEM));
    navItems.forEach(function (navItem, index) {
        navItem.addEventListener('click', function () {
            mainCarousel.transitionToIndex(index);
        });
    });
    return new Carousel(container, navItems, { allowLooping: false });
}
var OpenskyAppController = (function (_super) {
    __extends(OpenskyAppController, _super);
    function OpenskyAppController($element) {
        var _this = _super.call(this, $element, CssClass.APP_CHAPTERS, CssClass.APP_CHAPTER) || this;
        var element = $element[0];
        _this.nav_ = createNavCarousel(element, _this.getScrollCarousel());
        CarouselSyncManager.getSingleton()
            .syncCarousels(_this.getScrollCarousel(), _this.nav_);
        return _this;
    }
    OpenskyAppController.prototype.hasHorizontalScrollMode_ = function () {
        return true;
    };
    OpenskyAppController.prototype.allowLoopingWhenHorizontal_ = function () {
        return false;
    };
    OpenskyAppController.$inject = ['$element'];
    return OpenskyAppController;
}(ScrollCarouselController));
export { OpenskyAppController };
