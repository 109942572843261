import { ScrollEffect } from "toolbox/components/scroll-effect/base";
import { Tween } from "toolbox/components/scroll-effect/effects/tween/tween";
import { DistanceFunction } from "toolbox/components/scroll-effect/distance-function";
import { getOffsetFromAncestorIgnoringSticky } from "toolbox/utils/dom/position/vertical/get-offset-from-ancestor-ignoring-sticky";
import { NumericRange } from "toolbox/utils/math/numeric-range";
import { renderLoop } from "toolbox/utils/render-loop";
import { setStyle } from "toolbox/utils/dom/style/set-style";
import { UserAgent } from "toolbox/utils/user-agent/user-agent";
import { Edge } from "toolbox/utils/user-agent/browser/edge";
import { Safari } from "toolbox/utils/user-agent/browser/safari";
var CssClass;
(function (CssClass) {
    CssClass["IMAGES_CONTAINER"] = "opensky-images__content-scroll-area";
    CssClass["IMAGE"] = "opensky-images__image";
    CssClass["IMAGE_INNER"] = "opensky-images__image-inner";
})(CssClass || (CssClass = {}));
var SCALE_RANGE = new NumericRange(0.1, 2);
var TRANSLATION_OFFSET = 50 + SCALE_RANGE.getMin() * 100 / 2;
var TRANSLATION_RANGE = new NumericRange(0, TRANSLATION_OFFSET);
function createImagesLteMdScrollEffect(element) {
    var container = element.querySelector("." + CssClass.IMAGES_CONTAINER);
    var images = (Array.from(element.querySelectorAll("." + CssClass.IMAGE)).slice(1));
    var tweens = images.reduce(function (result, image, index) {
        var start = index / images.length;
        var end = index + 1 / images.length;
        result.push(new Tween([
            [
                start,
                "transform: translateY(" + TRANSLATION_RANGE.getMax() + "%) " +
                    ("scale(" + SCALE_RANGE.getMin() + ");")
            ],
            [
                end,
                "transform: translateY(" + TRANSLATION_RANGE.getMin() + "%) " +
                    ("scale(" + SCALE_RANGE.getMax() + ");")
            ],
        ], { styleTarget: image }));
        return result;
    }, []);
    return new ScrollEffect(container, {
        effects: tweens,
        getDistanceFunction: DistanceFunction.DOCUMENT_SCROLL,
        startDistance: function () { return getOffsetFromAncestorIgnoringSticky(container); },
        endDistance: function () {
            return getOffsetFromAncestorIgnoringSticky(container) +
                container.offsetHeight - window.innerHeight;
        },
        percentCallbacks: (images.map(function (image, index) {
            var start = index / images.length;
            var end = index + 1 / images.length;
            var range = new NumericRange(start, end);
            var innerImage = image.querySelector("." + CssClass.IMAGE_INNER);
            return [
                new NumericRange(start, end),
                [function (target, distanceAsPx, distancePercent) {
                        var percent = range.getValueAsPercent(distancePercent);
                        var containerScale = SCALE_RANGE.getPercentAsValue(percent);
                        var innerScale = 1 / containerScale;
                        var innerTranslate = TRANSLATION_RANGE.getPercentAsValue(1 - percent);
                        renderLoop.mutate(function () {
                            setStyle(innerImage, 'transform', "scale(" + innerScale + ") translateY(-" + innerTranslate + "%)");
                        });
                    }]
            ];
        })),
    });
}
var OpenskyImagesController = (function () {
    function OpenskyImagesController($element) {
        var element = $element[0];
        if (UserAgent.getBrowser() === Safari || UserAgent.getBrowser() === Edge) {
            this.imagesScrollEffectLteMd_ = null;
        }
        else {
            this.imagesScrollEffectLteMd_ = createImagesLteMdScrollEffect(element);
        }
    }
    OpenskyImagesController.$inject = ['$element'];
    return OpenskyImagesController;
}());
export { OpenskyImagesController };
