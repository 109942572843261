import { BrowserMarker } from 'toolbox/components/browser-marker/base';
import { onDomContentLoad } from 'toolbox/utils/dom/on-dom-content-load';
import { chromeOnlyJsScroll } from 'toolbox/components/js-scroll/chrome-only-js-scroll';
import { GetWindowHeight } from './get-window-height';
import * as angular from 'angular';
import { CarouselController } from '../partials/carousel/carousel-controller';
import { NeighborhoodDrawerSlideController } from '../partials/neighborhood-drawer-slide/neighborhood-drawer-slide-controller';
import { FullPeekingSectionController } from '../partials/full-peeking-section/full-peeking-section-controller';
import { RolodexCarouselNavController } from '../partials/rolodex-carousel-nav/rolodex-carousel-nav-controller';
import { ScrollCarouselController } from '../partials/scroll-carousel/scroll-carousel-controller';
import { HomeLocationsController } from '../partials/home-locations/home-locations-controller';
import { OrderJourneyController } from '../partials/order-journey/order-journey-controller';
import { AircraftController } from '../partials/aircraft/aircraft-controller';
import { TabSectionController } from '../partials/tab-section/tab-section-controller';
import { TopNavController } from '../partials/top-nav/top-nav-controller';
import { CarouselDotNavController } from '../partials/carousel-dot-nav/carousel-dot-nav-controller';
import { ContactFormController } from '../ts/contact-form';
import { IFrameFormController } from '../ts/iframe-form';
import { LegalNavController } from '../ts/legal-nav';
import { LazyLoadController } from './lazyload';
import { SignupFormController } from '../partials/signup-form/signup-form-controller';
import { UnsubscribeFormController } from '../partials/unsubscribe-form/unsubscribe-form-controller';
import { ImageCarouselAndGridController } from '../partials/image-carousel-and-grid/image-carousel-and-grid-controller';
import { JobBoardController } from '../partials/job-board/job-board-controller';
import { CountryNavController } from '../partials/country-nav/country-nav-controller';
import { RoleController } from '../partials/role/role-controller';
import { OpenskyAppController } from '../partials/opensky-app/opensky-app-controller';
import { ResourceArticlesController } from '../partials/resource-articles/resource-articles-controller';
import { UTMCarouselController } from '../partials/utm-grid-carousel/utm-grid-carousel-controller';
import { UTMFullScreenController } from '../partials/utm-full-screen/utm-full-screen-controller';
import { UTMPreloaderController } from '../partials/utm-preloader/utm-preloader-controller';
import { UTMCaseStudiesController } from '../partials/utm-case-studies/utm-case-studies-controller';
import { ContentPageHeroController } from '../partials/content-page-hero/content-page-hero-controller';
import { VideoController } from '../partials/video/video-controller';
import { bodymovinDirective } from './directives/bodymovin';
import { breakpointStyleDirective } from './directives/breakpoint-style';
import { inviewDirective } from './directives/inview';
import { scrollToDirective } from './directives/scroll-to';
import { scrollTweenDirective } from './directives/scroll-tween';
import { socialShareDirective } from './directives/social-sharing';
import youtubemodal from 'airkit/youtubemodal/index';
import { renderLoop } from 'toolbox/utils/render-loop';
import { OpenskyImagesController } from '../partials/opensky-images/opensky-images-controller';
import { BusinessesCarouselController } from '../partials/businesses-carousel/businesses-carousel-controller';
import { HomeHeroController } from '../partials/home-hero/home-hero-controller';
var MODULE_NAME = 'wing2019';
new BrowserMarker();
angular
    .module(MODULE_NAME, [])
    .config([
    '$interpolateProvider',
    function ($interpolateProvider) {
        $interpolateProvider.startSymbol('[[').endSymbol(']]');
    },
])
    .controller('CarouselController', CarouselController)
    .controller('CarouselDotNavController', CarouselDotNavController)
    .controller('NeighborhoodDrawerSlideController', NeighborhoodDrawerSlideController)
    .controller('FullPeekingSectionController', FullPeekingSectionController)
    .controller('HomeLocationsController', HomeLocationsController)
    .controller('AircraftController', AircraftController)
    .controller('ImageCarouselAndGridController', ImageCarouselAndGridController)
    .controller('LegalNavController', LegalNavController)
    .controller('OrderJourneyController', OrderJourneyController)
    .controller('OpenskyAppController', OpenskyAppController)
    .controller('OpenskyImagesController', OpenskyImagesController)
    .controller('RolodexCarouselNavController', RolodexCarouselNavController)
    .controller('ScrollCarouselController', ScrollCarouselController)
    .controller('TabSectionController', TabSectionController)
    .controller('TopNavController', TopNavController)
    .controller('ContactFormController', ContactFormController)
    .controller('IFrameFormController', IFrameFormController)
    .controller('SignupFormController', SignupFormController)
    .controller('UnsubscribeFormController', UnsubscribeFormController)
    .controller('JobBoardController', JobBoardController)
    .controller('RoleController', RoleController)
    .controller('BusinessesCarouselController', BusinessesCarouselController)
    .controller('CountryNavController', CountryNavController)
    .controller('HomeHeroController', HomeHeroController)
    .controller('ResourceArticlesController', ResourceArticlesController)
    .controller('UTMCarouselController', UTMCarouselController)
    .controller('UTMFullScreenController', UTMFullScreenController)
    .controller('UTMPreloaderController', UTMPreloaderController)
    .controller('UTMCaseStudiesController', UTMCaseStudiesController)
    .controller('ContentPageHeroController', ContentPageHeroController)
    .controller('VideoController', VideoController)
    .directive('bodymovin', bodymovinDirective)
    .directive('breakpointStyle', breakpointStyleDirective)
    .directive('inview', inviewDirective)
    .directive('scrollto', scrollToDirective)
    .directive('scrollTween', scrollTweenDirective)
    .directive('socialShare', socialShareDirective)
    .run(function () { return renderLoop.runScrollLoop(); });
function initFaviconForTheme() {
    var matcher = window.matchMedia('(prefers-color-scheme: dark)');
    var faviconEl = (document.querySelector('link[rel="shortcut icon"]'));
    matcher.addListener(function () {
        if (matcher.matches) {
            faviconEl.href = faviconEl.dataset.darkThemeHref;
        }
    });
}
onDomContentLoad(function () {
    angular.bootstrap(document.querySelector('body'), [MODULE_NAME]);
    youtubemodal.init({
        useHandlerOnMobile: false,
    });
    initFaviconForTheme();
    new GetWindowHeight();
    new LazyLoadController();
    handleIframeResize();
});
function handleIframeResize() {
    window.addEventListener('message', function (event) {
        var _a;
        var height = (_a = event.data) === null || _a === void 0 ? void 0 : _a.height;
        if (!height) {
            return;
        }
        var iframeElements = document.querySelectorAll('iframe[data-resize-iframe]');
        iframeElements.forEach(function (iframeElement) {
            if (event.source === iframeElement.contentWindow) {
                iframeElement.style.height = height + 120 + "px";
            }
        });
    });
}
chromeOnlyJsScroll.init();
