var ContactFormController = (function () {
    function ContactFormController($scope, $element) {
        this.element_ = $element[0];
        this.formEl_ = this.element_.querySelector('form');
    }
    ContactFormController.prototype.init = function () {
        this.setupGTM_();
    };
    ContactFormController.prototype.setupGTM_ = function () {
        var _this = this;
        var formStart = false;
        var eventCategory = 'form';
        var eventAction = 'change';
        var eventName = 'formChange';
        this.formEl_.addEventListener('change', function (e) {
            var eventLabel = e.target.dataset && e.target.dataset.label ? e.target.dataset.label : e.target.name;
            if (!formStart) {
                formStart = true;
                eventAction = 'start';
                eventName = 'formStart';
            }
            else {
                eventAction = 'change';
                eventName = 'formChange';
            }
            _this.submitGTMEvent_({
                category: eventCategory,
                label: eventLabel,
                action: eventAction,
                event: eventName
            });
        });
    };
    ContactFormController.prototype.submitGTMEvent_ = function (gtmEvent) {
        window.dataLayer.push(gtmEvent);
    };
    ContactFormController.prototype.trackSubmit = function () {
        this.submitGTMEvent_({
            category: 'form',
            action: 'submit',
            event: 'formSubmit',
            label: 'submit_button'
        });
    };
    ContactFormController.$inject = ['$scope', '$element'];
    return ContactFormController;
}());
export { ContactFormController };
