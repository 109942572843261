var VideoController = (function () {
    function VideoController($element) {
        this._$video = $element[0];
        this._intersectOpts = {
            threshold: 0.25
        };
        this._init();
    }
    VideoController.prototype._init = function () {
        this._$video.pause();
        this._inview();
    };
    VideoController.prototype._inview = function () {
        var _this = this;
        var observer = new IntersectionObserver(function (entries) {
            var entry = entries[0];
            var isInview = entry.isIntersecting;
            if (isInview) {
                _this._$video.play();
            }
            else {
                _this._$video.pause();
            }
        }, this._intersectOpts);
        observer.observe(this._$video);
    };
    VideoController.$inject = ['$element'];
    return VideoController;
}());
export { VideoController };
