import { IFrameForm } from '../../ts/iframe-form';
import { DynamicDefaultMap } from 'toolbox/utils/map/dynamic-default';
import { lstrip } from 'toolbox/utils/string/lstrip';
import { trim } from 'toolbox/utils/string/trim';
import recaptchaService from './../../ts/services/recaptcha-service';
var MAPS_ADDRESS_COMPONENTS = [
    'place_id',
    'lat',
    'lng',
    'street',
    'suburb',
    'city',
    'state',
    'postal_code',
    'country',
];
var TEL_REGEXES = [
    /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
    /^((([\+][\s]{0,1})|([0]{2}[\s-]{0,1}))([358]{3})([\s-]{0,1})|([0]{1}))(([1-9]{1}[0-9]{0,1})([\s-]{0,1})([0-9]{2,4})([\s-]{0,1})([0-9]{2,4})([\s-]{0,1}))([0-9]{0,3}){1}$/,
    /^(?:\+?(1))? ?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
];
var SignupFormController = (function () {
    function SignupFormController($scope, $element, $http) {
        this.loading = false;
        this.submitted = false;
        this.page = 0;
        this.numPages = 0;
        this.streetName = null;
        this.streetNumber = null;
        this.selectedPlaceAddress_ = '';
        this.scope_ = $scope;
        this.element_ = $element[0];
        this.http_ = $http;
        this.progressAttempted_ = DynamicDefaultMap.usingFunction(function () { return false; });
        this.formEl_ = this.element_.querySelector('form');
        var iframeEl = this.element_.querySelector('iframe');
        this.form_ = new IFrameForm(this.formEl_, iframeEl);
        this.recaptchaService = new recaptchaService(this.formEl_);
    }
    SignupFormController.prototype.init = function (options) {
        this.numPages = options.numPages;
        this.initMapsAutocomplete();
        this.setupGTM_();
    };
    SignupFormController.prototype.setupGTM_ = function () {
        var _this = this;
        var formStart = false;
        this.formEl_.addEventListener('change', function (e) {
            var eventAction = 'change';
            var eventCategory = 'form';
            var eventLabel = e.target.dataset && e.target.dataset.label ? e.target.dataset.label : e.target.name;
            if (e.target.type === 'radio') {
                eventLabel = e.target.value;
            }
            if (!formStart) {
                formStart = true;
                _this.submitGTMEvent_({
                    category: eventCategory,
                    action: 'start',
                    event: 'formStart',
                    label: eventLabel
                });
            }
            _this.submitGTMEvent_({
                category: eventCategory,
                label: eventLabel,
                action: eventAction,
                event: 'formChange'
            });
        });
    };
    SignupFormController.prototype.submitGTMEvent_ = function (gtmEvent) {
        window.dataLayer.push(gtmEvent);
    };
    SignupFormController.prototype.initMapsAutocomplete = function () {
        this.initAddressAutocomplete();
    };
    SignupFormController.prototype.debug = function () {
        var search = new URLSearchParams(location.search);
        return search.get('debug') === 'true';
    };
    SignupFormController.prototype.initAddressAutocomplete = function () {
        var _this = this;
        var addressEl = this.formEl_.querySelector('.maps-address');
        if (!addressEl) {
            return;
        }
        addressEl.addEventListener('blur', function (e) {
            setTimeout(function () {
                _this.onMapsAddressChanged(addressEl);
            }, 250);
        });
        var ac = new google.maps.places.Autocomplete(addressEl, {
            types: ['address'],
        });
        ac.addListener('place_changed', function (e) {
            var place = ac.getPlace();
            if (!place) {
                return;
            }
            _this.onPlaceChanged(addressEl, place);
        });
    };
    SignupFormController.prototype.onMapsAddressChanged = function (addressEl) {
        var _this = this;
        if (addressEl.value === this.selectedPlaceAddress_) {
            return;
        }
        this.selectedPlaceAddress_ = '';
        this.resetMapsHiddenFields();
        if (!addressEl.value) {
            return;
        }
        var autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions({
            input: addressEl.value,
            types: ['address'],
        }, function (results, autocompleteStatus) {
            if (autocompleteStatus == google.maps.places.PlacesServiceStatus.OK && results.length > 0) {
                var autocompleteResult_1 = results[0];
                var placesService = new google.maps.places.PlacesService(addressEl);
                placesService.getDetails({
                    placeId: autocompleteResult_1.place_id,
                }, function (place, placeStatus) {
                    if (placeStatus == google.maps.places.PlacesServiceStatus.OK && place) {
                        addressEl.value = autocompleteResult_1.description;
                        _this.onPlaceChanged(addressEl, place);
                    }
                });
            }
        });
    };
    SignupFormController.prototype.onPlaceChanged = function (addressEl, place) {
        var placeId = place.place_id;
        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
        var addr = this.parseAddress_(place);
        var alertText = this.formEl_.getAttribute('data-alert-text');
        if (this.debug()) {
            console.log(place);
            console.log(addr);
        }
        this.selectedPlaceAddress_ = addressEl.value;
        addressEl.classList.remove('form-field-invalid');
        var mapsAddressFields = {
            place_id: placeId,
            lat: '' + lat,
            lng: '' + lng,
            street: addr.street,
            suburb: addr.suburb,
            city: addr.city,
            state: addr.state,
            postal_code: addr.postal_code,
            country: addr.country,
        };
        this.updateMapsHiddenFields(mapsAddressFields);
        window.alert(alertText + "\n\n" + this.selectedPlaceAddress_);
    };
    SignupFormController.prototype.resetMapsHiddenFields = function () {
        var _this = this;
        MAPS_ADDRESS_COMPONENTS.forEach(function (key) {
            var fieldEl = _this.formEl_.querySelector('.maps-address-' + key);
            if (fieldEl) {
                fieldEl.value = '';
            }
        });
    };
    SignupFormController.prototype.updateMapsHiddenFields = function (fields) {
        var _this = this;
        MAPS_ADDRESS_COMPONENTS.forEach(function (key) {
            var fieldEl = _this.formEl_.querySelector('.maps-address-' + key);
            if (fieldEl) {
                fieldEl.value = fields[key];
            }
        });
    };
    SignupFormController.prototype.parseAddress_ = function (place) {
        if (place['adr_address']) {
            return this.parseAdrMicroformat_(place);
        }
        var components = {
            'street_number': 'short_name',
            'route': 'long_name',
            'locality': 'long_name',
            'administrative_area_level_1': 'short_name',
            'postal_code': 'short_name',
            'country': 'short_name'
        };
        var data = {};
        for (var i = 0; i < place['address_components'].length; i++) {
            var addressType = place['address_components'][i].types[0];
            if (components[addressType]) {
                var component = place['address_components'][i];
                var key = components[addressType];
                var val = (key == 'short_name') ? component.short_name : component.long_name;
                data[addressType] = val;
            }
        }
        var state = data['administrative_area_level_1'];
        var city = data['locality'];
        var postal_code = data['postal_code'];
        var country = this.getAddressComponent_(place, 'country').short_name;
        if (country == 'US' && state == 'NY' && (!city || city == 'New York')) {
            var component = this.getAddressComponent_(place, 'sublocality_level_1');
            if (component) {
                city = component.long_name;
            }
        }
        var suburb = '';
        if (country == 'AU') {
            var suburbComponent = this.getAddressComponent_(place, 'locality');
            var cityComponent = this.getAddressComponent_(place, 'administrative_area_level_2');
            if (suburbComponent && cityComponent) {
                city = cityComponent['long_name'];
                suburb = suburbComponent['long_name'];
            }
            else {
                suburb = city;
                if (state == 'ACT') {
                    city = 'Canberra';
                }
            }
        }
        var street = null;
        ;
        if (data['street_number'] && data['route']) {
            street = data['street_number'] + ' ' + data['route'];
        }
        var address = {
            'street': street,
            'suburb': suburb,
            'city': city,
            'state': state,
            'postal_code': postal_code,
            'country': country,
        };
        return address;
    };
    SignupFormController.prototype.getAddressComponent_ = function (place, type) {
        for (var i = 0; i < place['address_components'].length; i++) {
            var component = place['address_components'][i];
            if (component['types'].includes(type)) {
                return component;
            }
        }
        return null;
    };
    SignupFormController.prototype.parseAdrMicroformat_ = function (place) {
        var adr = place['adr_address'];
        var el = document.createElement('div');
        el.innerHTML = adr;
        var getText = function (query) {
            var child = el.querySelector(query);
            if (child) {
                return child.textContent || null;
            }
            return null;
        };
        var country = this.getAddressComponent_(place, 'country').short_name;
        var suburb = '';
        var city = getText('.locality');
        var state = getText('.region');
        if (country == 'AU') {
            var suburbComponent = this.getAddressComponent_(place, 'locality');
            var cityComponent = this.getAddressComponent_(place, 'administrative_area_level_2');
            if (suburbComponent && cityComponent) {
                city = cityComponent['long_name'];
                suburb = suburbComponent['long_name'];
            }
            else {
                suburb = city;
                if (state == 'ACT') {
                    city = 'Canberra';
                }
            }
        }
        var address = {
            'street': getText('.street-address'),
            'city': city,
            'suburb': suburb,
            'state': state,
            'postal_code': getText('.postal-code'),
            'country': country,
        };
        return address;
    };
    SignupFormController.prototype.initCityCountryAutocomplete = function () {
        var cityEl = this.formEl_.querySelector('[name="city"]');
        if (!cityEl) {
            return;
        }
        var countryEl = this.formEl_.querySelector('[name="country"]');
        var ac = new google.maps.places.Autocomplete(cityEl, {
            types: ['(cities)'],
        });
        ac.addListener('place_changed', function (e) {
            var place = ac.getPlace();
            if (!place) {
                return;
            }
            var cityName = place['name'];
            if (cityName) {
                cityEl.value = cityName;
            }
            if (countryEl) {
                var countryPart = place['address_components'].find(function (component) {
                    return component['types'].includes('country');
                });
                if (countryPart) {
                    var countryName = countryPart['short_name'];
                    for (var i = 0; i < countryEl.options.length; i++) {
                        var option = countryEl.options[i];
                        if (option.value === countryName) {
                            option.selected = true;
                            break;
                        }
                    }
                }
            }
        });
    };
    SignupFormController.prototype.prevPage = function () {
        var prevIndex = this.page - 1;
        if (prevIndex < 0) {
            return;
        }
        this.page = prevIndex;
        this.scrollToTop();
    };
    SignupFormController.prototype.nextPage = function () {
        var nextIndex = this.page + 1;
        this.progressAttempted_.set(this.page, true);
        if (nextIndex >= this.numPages) {
            return;
        }
        var valid = this.isPageValid(this.page);
        this.toggleRequiredError(this.page, !valid);
        if (!valid) {
            return;
        }
        this.page = nextIndex;
        this.scrollToTop();
    };
    SignupFormController.prototype.scrollToTop = function () {
        var offset = 200;
        var y = (this.form_.getElement().getBoundingClientRect().top
            - document.body.getBoundingClientRect().top
            - offset);
        window.scrollTo({
            top: y,
            behavior: 'smooth',
        });
    };
    SignupFormController.prototype.isPageValid = function (pageIndex) {
        var _this = this;
        var selector = "[data-page-index=\"" + pageIndex + "\"] [required]";
        var requiredFields = this.element_.querySelectorAll(selector);
        var isValid = true;
        Array.from(requiredFields).forEach(function (field) {
            if (!_this.isFieldValid(field)) {
                isValid = false;
            }
        });
        return isValid;
    };
    SignupFormController.prototype.validate = function () {
        if (!this.progressAttempted_.get(this.page)) {
            return;
        }
        this.toggleRequiredError(this.page, !this.isPageValid(this.page));
    };
    SignupFormController.prototype.isFieldValid = function (field) {
        if (field.type === 'checkbox') {
            return field.checked;
        }
        if (field.classList.contains('maps-address')) {
            var isValid = false;
            if (field.value) {
                var placeIdEl = this.formEl_.querySelector('.maps-address-place_id');
                isValid = Boolean(placeIdEl && placeIdEl.value);
            }
            field.classList.toggle('form-field-invalid', !isValid);
            return isValid;
        }
        if (field.type === 'tel') {
            var tel_1 = field.value;
            var isValid = Boolean(tel_1) && TEL_REGEXES.some(function (pattern) { return tel_1.match(pattern); });
            field.classList.toggle('form-field-invalid', !isValid);
            return isValid;
        }
        return Boolean(field.value);
    };
    SignupFormController.prototype.getSalesforceDate = function () {
        var date = new Date();
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        var day = ('0' + date.getDate()).slice(-2);
        return date.getFullYear() + '-' + month + '-' + day;
    };
    SignupFormController.prototype.toggleRequiredError = function (pageIndex, enabled) {
        var selector = "[data-page-index=\"" + pageIndex + "\"]";
        var el = this.element_.querySelector(selector);
        el.classList.toggle('signup-form__page--required-error-visible', enabled);
    };
    SignupFormController.prototype.submit = function ($event) {
        if ($event) {
            $event.preventDefault();
        }
        if (this.loading) {
            return;
        }
        var valid = this.isPageValid(this.page);
        this.toggleRequiredError(this.page, !valid);
        if (!valid) {
            return;
        }
        this.loading = true;
        if (this.recaptchaService.isRecaptchaEnabled()) {
            this.recaptchaService.init(this.onFormSubmit.bind(this));
        }
    };
    SignupFormController.prototype.onFormSubmit = function (token, timestamp) {
        var _this = this;
        this.http_.post(this.formEl_.getAttribute('data-recaptcha-url'), {
            recaptcha_response: token
        })
            .then(function (res) {
            if (res.status !== 200 || !res.data.success) {
                throw new Error('reCAPTCHA validation failed: ' + res.data['error-codes']);
            }
            console.log('reCAPTCHA passed');
            _this.form_.submit().then(function () {
                _this.loading = false;
                _this.submitted = true;
                if (timestamp !== 0)
                    clearInterval(timestamp);
                _this.scrollToTop();
                _this.updateHash('#thank-you');
                _this.updateScope_();
                if (fbq) {
                    fbq('track', 'CompleteRegistration');
                }
                if (gtag_report_conversion) {
                    gtag_report_conversion();
                }
            });
        })
            .catch(function (error) {
            console.error(error);
        });
        this.submitGTMEvent_({
            category: 'form',
            action: 'submit',
            event: 'formSubmit',
            label: 'submit_button'
        });
    };
    SignupFormController.prototype.splitStreetNumber = function () {
        var streetName = this.getSplitStreetName_(this.streetNumber);
        this.streetNumber =
            trim(this.streetNumber.slice(0, this.streetNumber.indexOf(streetName)));
    };
    SignupFormController.prototype.getSplitStreetName_ = function (value) {
        return lstrip(value, ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', ' ']);
    };
    SignupFormController.prototype.splitStreetName = function () {
        this.streetName = this.getSplitStreetName_(this.streetName);
    };
    SignupFormController.prototype.updateScope_ = function () {
        if (!this.scope_.$$phase) {
            this.scope_.$apply();
        }
    };
    SignupFormController.prototype.updateHash = function (url) {
        history.replaceState(null, null, "" + url);
        this.submitGTMEvent_({
            'event': 'pageView',
            'pagePath': document.location.pathname,
            'pageTitle': document.title,
            'category': 'page',
            'action': 'view',
            'label': url
        });
    };
    SignupFormController.$inject = ['$scope', '$element', '$http'];
    return SignupFormController;
}());
export { SignupFormController };
