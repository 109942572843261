import { Carousel } from "toolbox/components/carousel/carousel";
import { CarouselSyncManager } from "toolbox/components/carousel/sync-manager";
import { PhysicalSlide } from "toolbox/components/carousel/transitions/physical-slide";
import { CssClass } from "./css-class";
function createSpecsCarousel(element) {
    var container = element.querySelector("." + CssClass.SPECS_SLIDES_CONTAINER);
    var slides = Array.from(element.querySelectorAll("." + CssClass.SPECS_SLIDE));
    var carousel = new Carousel(container, slides, {
        transition: new PhysicalSlide(),
        allowLooping: false,
    });
    var nav = element.querySelector("." + CssClass.SPECS_NAV);
    var dots = Array.from(element.querySelectorAll("." + CssClass.SPECS_NAV_DOT));
    var navCarousel = new Carousel(nav, dots, { allowLooping: false });
    slides.forEach(function (slide, index) {
        slide.addEventListener('click', function () { return carousel.transitionToIndex(index); });
    });
    CarouselSyncManager.getSingleton().syncCarousels(navCarousel, carousel);
    return carousel;
}
export { createSpecsCarousel };
