var subNavItemSelector = '.content-page-hero__subnav__item';
var ContentPageHeroController = (function () {
    function ContentPageHeroController($element) {
        this._$el = $element[0];
        this._$subNavItems = Array.from(this._$el.querySelectorAll(subNavItemSelector));
        this._activeClass = 'active';
        this._init();
    }
    ContentPageHeroController.prototype._init = function () {
        var _this = this;
        if (this._$subNavItems.length > 1) {
            this._$subNavItems.forEach(function (item) { return item.addEventListener('mouseover', function (e) { return _this._removeActiveClass(e); }); });
        }
    };
    ContentPageHeroController.prototype._removeActiveClass = function (e) {
        var _this = this;
        this._$subNavItems.forEach(function (item) {
            item.classList.remove(_this._activeClass);
        });
    };
    ContentPageHeroController.$inject = ['$element'];
    return ContentPageHeroController;
}());
export { ContentPageHeroController };
