import { Carousel } from "toolbox/components/carousel/carousel";
import { CssClass } from "./css-class";
import { CarouselSyncManager } from "toolbox/components/carousel/sync-manager";
import { renderLoop } from "toolbox/utils/render-loop";
import { addClassIfMissing } from "toolbox/utils/dom/class/add-class-if-missing";
import { removeClassIfPresent } from "toolbox/utils/dom/class/remove-class-if-present";
import { PhysicalSlide } from "toolbox/components/carousel/transitions/physical-slide";
var HighlightCarousel = (function () {
    function HighlightCarousel(element) {
        this.element_ = element;
        this.container_ =
            element.querySelector("." + CssClass.HIGHLIGHT_SLIDES_CONTAINER);
        var slides = Array.from(element.querySelectorAll("." + CssClass.HIGHLIGHT_SLIDE));
        this.fadeCarousel_ = new Carousel(this.container_, slides);
        this.physicalCarousel_ =
            new Carousel(this.container_, slides, {
                condition: function () { return window.innerWidth <= 768; },
                transition: new PhysicalSlide(),
                allowLooping: false,
            });
        this.previousNumber_ =
            element.querySelector("." + CssClass.HIGHLIGHT_NUMBER_PREV);
        this.nextNumber_ =
            element.querySelector("." + CssClass.HIGHLIGHT_NUMBER_NEXT);
        this.init_();
    }
    HighlightCarousel.prototype.init_ = function () {
        CarouselSyncManager.getSingleton()
            .syncCarousels(this.fadeCarousel_, this.physicalCarousel_);
        this.initArrows_();
        this.initCloseButton_();
        this.initDots_();
        this.renderLoop_();
    };
    HighlightCarousel.prototype.initDots_ = function () {
        var _this = this;
        var dots = Array.from(this.element_.querySelectorAll("." + CssClass.HIGHLIGHT_SLIDE_DOT));
        dots.forEach(function (dot) {
            dot.addEventListener('mouseover', function () {
                renderLoop.measure(function () {
                    var slideNumber = parseInt(dot.dataset.highlightNumber);
                    _this.fadeCarousel_.transitionToIndex(slideNumber);
                    addClassIfMissing(_this.container_, CssClass.HIGHLIGHT_SLIDES_CONTAINER_VISIBLE);
                });
            });
            dot.addEventListener('mouseout', function () {
                renderLoop.measure(function () { return removeClassIfPresent(_this.container_, CssClass.HIGHLIGHT_SLIDES_CONTAINER_VISIBLE); });
            });
            dot.addEventListener('click', function () {
                renderLoop.measure(function () {
                    var slideNumber = parseInt(dot.dataset.highlightNumber);
                    _this.physicalCarousel_.transitionToIndex(slideNumber);
                    addClassIfMissing(_this.container_, CssClass.HIGHLIGHT_SLIDES_CONTAINER_MODAL_OPEN);
                });
            });
        });
    };
    HighlightCarousel.prototype.initCloseButton_ = function () {
        var _this = this;
        var closeButton = this.element_.querySelector("." + CssClass.HIGHLIGHT_CLOSE_BUTTON);
        closeButton.addEventListener('click', function () { return removeClassIfPresent(_this.container_, CssClass.HIGHLIGHT_SLIDES_CONTAINER_MODAL_OPEN); });
    };
    HighlightCarousel.prototype.initArrows_ = function () {
        var _this = this;
        var prevArrow = this.element_.querySelector("." + CssClass.HIGHLIGHT_ARROW_PREV + " ." + CssClass.HIGHLIGHT_ARROW_IMAGE);
        var nextArrow = this.element_.querySelector("." + CssClass.HIGHLIGHT_ARROW_NEXT + " ." + CssClass.HIGHLIGHT_ARROW_IMAGE);
        prevArrow.addEventListener('click', function () { return _this.physicalCarousel_.previous(); });
        nextArrow.addEventListener('click', function () { return _this.physicalCarousel_.next(); });
    };
    HighlightCarousel.prototype.renderLoop_ = function () {
        var _this = this;
        renderLoop.measure(function () {
            renderLoop.cleanup(function () { return _this.renderLoop_(); });
            var activeIndex = _this.fadeCarousel_.getActiveSlideIndex();
            var slideCount = _this.fadeCarousel_.getSlides().length;
            var nextNumber = activeIndex < slideCount - 1 ? "0" + (activeIndex + 2) : '';
            var prevNumber = activeIndex > 0 ? "0" + activeIndex : '';
            var previousArrow = _this.element_.querySelector("." + CssClass.HIGHLIGHT_ARROW_PREV);
            if (activeIndex === 0) {
                addClassIfMissing(previousArrow, CssClass.HIGHLIGHT_ARROW_HIDDEN);
            }
            else {
                removeClassIfPresent(previousArrow, CssClass.HIGHLIGHT_ARROW_HIDDEN);
            }
            var nextArrow = _this.element_.querySelector("." + CssClass.HIGHLIGHT_ARROW_NEXT);
            if (activeIndex === slideCount - 1) {
                addClassIfMissing(nextArrow, CssClass.HIGHLIGHT_ARROW_HIDDEN);
            }
            else {
                removeClassIfPresent(nextArrow, CssClass.HIGHLIGHT_ARROW_HIDDEN);
            }
            var currentNext = _this.nextNumber_.innerText;
            if (currentNext !== nextNumber) {
                renderLoop.mutate(function () { return _this.nextNumber_.innerText = nextNumber; });
            }
            var currentPrev = _this.previousNumber_.innerText;
            if (currentPrev !== prevNumber) {
                renderLoop.mutate(function () { return _this.previousNumber_.innerText = prevNumber; });
            }
        });
    };
    return HighlightCarousel;
}());
export { HighlightCarousel };
