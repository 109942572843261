import { Carousel } from "toolbox/components/carousel/carousel";
import { CarouselSyncManager } from "toolbox/components/carousel/sync-manager";
var contentSlideSelector = '.utm-grid__section--content [utm-case-studies-slide]';
var imageSlideSelector = '.utm-grid__section--image [utm-case-studies-slide]';
var carouselTriggerSelector = '[utm-case-studies-trigger]';
var UTMCaseStudiesController = (function () {
    function UTMCaseStudiesController($element) {
        this._$el = $element[0];
        this._$w = window;
        this._index = 0;
        this._previousIndex = 0;
        this._$contentSlides = Array.from(this._$el.querySelectorAll(contentSlideSelector));
        this._$imageSlides = Array.from(this._$el.querySelectorAll(imageSlideSelector));
        this._$triggers = Array.from(this._$el.querySelectorAll(carouselTriggerSelector));
        this._contentSlideCarousel = new Carousel(this._$el, this._$contentSlides);
        this._imageSlideCarousel = new Carousel(this._$el, this._$imageSlides);
        this._triggerCarousel = new Carousel(this._$el, this._$triggers);
        this.syncWithCarousel(this._imageSlideCarousel, this._contentSlideCarousel);
        this.syncWithCarousel(this._triggerCarousel, this._contentSlideCarousel);
        this._init();
    }
    UTMCaseStudiesController.prototype._init = function () {
        var _this = this;
        if (this._$triggers.length > 0) {
            this._$triggers.forEach(function (trigger) {
                trigger.addEventListener('click', function (e) {
                    var attributes = [].filter.call(trigger.attributes, function (at) { return /^utm-/.test(at.name); }).map(function (el) { return el.name; });
                    var triggerIndex = parseInt(attributes[attributes.length - 1].split('--').slice(-1)[0], 10);
                    _this.setCurrentSlide(triggerIndex - 1);
                });
            });
        }
    };
    UTMCaseStudiesController.prototype.setCurrentSlide = function (index) {
        this._previousIndex = this._index;
        this._index = (index > this._$contentSlides.length - 1) ? 0 : (index < 0) ? this._$contentSlides.length - 1 : index;
        this.goToSlide(this._contentSlideCarousel, this._index);
    };
    UTMCaseStudiesController.prototype.syncWithCarousel = function (carouselToSync, carousel) {
        CarouselSyncManager.getSingleton().syncCarousels(carouselToSync, carousel);
    };
    UTMCaseStudiesController.prototype.goToSlide = function (carousel, slideIndex) {
        carousel.transitionToIndex(slideIndex);
    };
    UTMCaseStudiesController.$inject = ['$element'];
    return UTMCaseStudiesController;
}());
export { UTMCaseStudiesController };
