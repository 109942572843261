var Breakpoint;
(function (Breakpoint) {
    Breakpoint[Breakpoint["XS"] = 330] = "XS";
    Breakpoint[Breakpoint["SM"] = 460] = "SM";
    Breakpoint[Breakpoint["MD"] = 768] = "MD";
    Breakpoint[Breakpoint["LG"] = 1024] = "LG";
    Breakpoint[Breakpoint["XL"] = 1440] = "XL";
    Breakpoint[Breakpoint["XXL"] = 1920] = "XXL";
})(Breakpoint || (Breakpoint = {}));
export { Breakpoint };
