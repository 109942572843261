var ScrollToController = (function () {
    function ScrollToController($element) {
        this.el = $element[0];
        this.w = window;
        this.targetElID = this.el.hash;
        this.targetEl = document.querySelector(this.targetElID);
        if (this.targetEl) {
            this.init();
        }
    }
    ScrollToController.prototype.init = function () {
        var _this = this;
        this.el.addEventListener('click', function (e) { return _this.scrollToElement(e); });
    };
    ScrollToController.prototype.scrollToElement = function (e) {
        e.preventDefault();
        var currentScrollPos = this.w.scrollY;
        var top = this.targetEl.getBoundingClientRect().top;
        var distanceToScroll = top + currentScrollPos;
        this.w.scrollTo({
            top: distanceToScroll,
            left: 0,
            behavior: 'smooth'
        });
        this.updateHash(this.targetElID);
    };
    ScrollToController.prototype.updateHash = function (url) {
        history.replaceState(null, null, "" + url);
    };
    ScrollToController.$inject = ['$element'];
    return ScrollToController;
}());
var scrollToDirective = function () {
    return {
        restrict: 'A',
        controller: ScrollToController,
    };
};
export { scrollToDirective };
