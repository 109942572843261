import Lottie from 'lottie-web/build/player/lottie';
import { isDefined } from "toolbox/utils/is-defined";
import { ScrollEffect } from "toolbox/components/scroll-effect/base";
import { ScrubLottie } from "toolbox/components/scroll-effect/effects/scrub-lottie";
import { bodymovinConfigs } from "./bodymovin-configs";
var BodymovinController = (function () {
    function BodymovinController($element, $scope) {
        var _this = this;
        this.destroyed_ = false;
        this.element_ = $element[0];
        var dataPath = this.element_.dataset.bodymovinDataPath;
        this.animation_ = Lottie.loadAnimation({
            container: this.element_,
            path: dataPath,
            loop: true,
            autoplay: true,
            renderer: 'svg',
        });
        if (isDefined(this.element_.dataset.bodymovinScrubConfig)) {
            this.setupScrub_(this.element_.dataset.bodymovinScrubConfig);
        }
        $scope.$on('$destroy', function () { return _this.dispose_(); });
    }
    BodymovinController.prototype.setupScrub_ = function (config) {
        this.scrub_ = new ScrollEffect(this.element_, Object.assign({}, bodymovinConfigs.get(config)(this.element_), { effects: [new ScrubLottie(this.animation_)] }));
    };
    BodymovinController.prototype.dispose_ = function () {
        this.destroyed_ = true;
        this.animation_.destroy();
    };
    BodymovinController.$inject = ['$element', '$scope'];
    return BodymovinController;
}());
var bodymovinDirective = function () {
    return {
        restrict: 'A',
        controller: BodymovinController,
    };
};
export { bodymovinDirective };
